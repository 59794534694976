/*!
    *
    * Wijmo Library 5.20191.603
    * http://wijmo.com/
    *
    * Copyright(c) GrapeCity, Inc.  All rights reserved.
    *
    * Licensed under the GrapeCity Commercial License.
    * sales@wijmo.com
    * wijmo.com/products/wijmo-5/license/
    *
    */
.wj-glyph, .wj-glyph-asterisk, .wj-glyph-calendar, .wj-glyph-check, .wj-glyph-circle, .wj-glyph-clipboard, .wj-glyph-clock, .wj-glyph-diamond, .wj-glyph-dimension, .wj-glyph-down, .wj-glyph-down-left, .wj-glyph-down-right, .wj-glyph-drag, .wj-glyph-file, .wj-glyph-left, .wj-glyph-measure, .wj-glyph-plus, .wj-glyph-minus, .wj-glyph-pencil, .wj-glyph-right, .wj-glyph-square, .wj-glyph-step-backward, .wj-glyph-step-forward, .wj-glyph-up, .wj-glyph-up-left, .wj-glyph-up-right, .wj-glyph-filter {
    position: relative;
    display: inline-block;
    box-sizing: border-box;
    text-align: center;
    opacity: .75;
    cursor: pointer
}

/* make active filter images 25% larger */
.wj-filter-on .wj-glyph-filter {
    color: white;
}

.wj-filter-on button{
    background-color: var(--secondary)!important;
}

.wj-glyph-asterisk:after {
    content: '\2731\fe0e'
}

.wj-glyph-calendar {
    width: 1em;
    height: 1em;
    border: .1em solid;
    border-top: .3em solid;
    transform: translateY(.15em)
}

.wj-glyph-calendar:after {
    position: absolute;
    font-size: 50%;
    left: .5em;
    content: '1'
}

.wj-glyph-check:after {
    content: '\2714\fe0e';
    font-weight: 700
}

.wj-glyph-circle {
    border: .3em solid;
    border-radius: 50%;
    transform: translateY(-.1em)
}

.wj-glyph-clipboard:after {
    content: '\1f4cb\fe0e'
}

.wj-glyph-clock {
    width: 1em;
    height: 1em;
    border: .1em solid;
    border-radius: 50%;
    transform: translateY(.15em)
}

.wj-glyph-clock:after {
    position: absolute;
    border-left: .1em solid;
    border-bottom: .1em solid;
    width: .25em;
    height: .3em;
    top: .1em;
    left: .35em;
    content: ''
}

.wj-glyph-diamond:after {
    content: '\25c6\fe0e'
}

.wj-glyph-dimension:after {
    content: '\27c0\fe0e'
}

.wj-glyph-down {
    top: -1px;
    border-top: .5em solid;
    border-right: .4em solid transparent;
    border-left: .4em solid transparent
}

.wj-glyph-down-left {
    border-top: .65em solid transparent;
    border-left: .65em solid
}

.wj-glyph-down-right {
    top: -2px;
    border-bottom: .65em solid;
    border-left: .65em solid transparent
}

.wj-glyph-drag {
    width: 1em;
    height: 1em
}

.wj-glyph-drag:after {
    content: ' ';
    position: absolute;
    top: .15em;
    left: .1em;
    width: 1em;
    height: 1em;
    background: radial-gradient(#000 1px, transparent 2px);
    background-size: .33em .33em
}

.wj-glyph-file {
    border-left: .7em solid;
    height: .85em
}

.wj-glyph-file:after {
    position: absolute;
    transform: translateX(-100%);
    border-top: .3em solid transparent;
    border-left: .3em solid #fff;
    content: ''
}

.wj-glyph-left {
    border-top: .4em solid transparent;
    border-right: .5em solid;
    border-bottom: .4em solid transparent
}

.wj-glyph-measure:after {
    content: '\1f4ca\fe0e'
}

.wj-glyph-plus, .wj-glyph-minus {
    border-top: .25em solid;
    width: .9em;
    top: -.3em
}

.wj-glyph-plus:after {
    position: absolute;
    box-sizing: border-box;
    border-left: .25em solid;
    width: .25em;
    height: .95em;
    left: .3em;
    top: -.6em;
    content: ''
}

.wj-glyph-pencil:after {
    content: '\270e\fe0e'
}

.wj-glyph-right {
    border-top: .4em solid transparent;
    border-bottom: .4em solid transparent;
    border-left: .5em solid
}

.wj-glyph-square {
    border: .25em solid
}

.wj-glyph-step-backward {
    border-top: .4em solid transparent;
    border-right: .5em solid;
    border-bottom: .4em solid transparent;
    transform: translateX(.12em)
}

.wj-glyph-step-backward:after {
    position: absolute;
    border-left: .2em solid;
    height: .75em;
    transform: translate(-100%, -50%);
    content: ''
}

[dir=rtl] .wj-glyph-step-backward:after {
    transform: translate(0, -50%)
}

.wj-glyph-step-forward {
    border-top: .4em solid transparent;
    border-bottom: .4em solid transparent;
    border-left: .5em solid
}

.wj-glyph-step-forward:after {
    position: absolute;
    border-left: .2em solid;
    height: .75em;
    transform: translateY(-50%);
    content: ''
}

[dir=rtl] .wj-glyph-step-forward:after {
    transform: translate(100%, -50%)
}

.wj-glyph-up {
    border-right: .4em solid transparent;
    border-bottom: .5em solid;
    border-left: .4em solid transparent
}

.wj-glyph-up-left {
    border-bottom: .65em solid transparent;
    border-left: .65em solid
}

.wj-glyph-up-right {
    border-top: .65em solid;
    border-left: .65em solid transparent
}

.wj-glyph-filter {
    top: -.1em;
    width: .5em;
    border-top: .5em solid;
    border-right: .4em solid transparent;
    border-left: .4em solid transparent
}

.wj-glyph-filter:after {
    position: absolute;
    border-left: .2em solid;
    height: .4em;
    transform: translateX(-50%);
    top: -.2em;
    content: ''
}

[dir=rtl] .wj-glyph-filter:after {
    transform: translateX(50%)
}

.wj-control {
    display: block;
    box-sizing: border-box;
    color: #000;
    background: #fff;
    user-select: none;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none
}

.wj-control * {
    box-sizing: inherit
}

.wj-control[disabled] {
    opacity: .6;
    cursor: default;
    pointer-events: none
}

.wj-control [contenteditable] {
    user-select: text;
    -webkit-user-select: text;
    -moz-user-select: text;
    -ms-user-select: text
}

.wj-control:focus, .wj-control :focus, .wj-control a:focus {
    outline: 0
}

.wj-control input::-ms-clear {
    display: none
}

.wj-control input[type=number]::-webkit-inner-spin-button, .wj-control input[type=number]::-webkit-outer-spin-button {
    -webkit-appearance: none;
    margin: 0
}

.wj-control input:invalid {
    box-shadow: none;
    outline: 0
}

.wj-control .wj-btn, .wj-viewer .wj-control .wj-applybutton {
    background: #eee;
    color: #000;
    cursor: pointer;
    display: inline-block;
    border-style: none;
    padding: 0 10px;
    text-align: center;
    min-width: 30px;
    white-space: nowrap
}

.wj-control .wj-btn:focus, .wj-viewer .wj-control .wj-applybutton:focus, .wj-control .wj-btn:hover, .wj-viewer .wj-control .wj-applybutton:hover {
    background: #e1e1e1;
    transition-duration: .4s
}

.wj-control .wj-btn[disabled], .wj-viewer .wj-control .wj-applybutton[disabled] {
    opacity: .6;
    cursor: default;
    pointer-events: none
}

.wj-control a.wj-btn, .wj-viewer .wj-control a.wj-applybutton, .wj-control button.wj-btn:not(.wj-btn-default), .wj-viewer .wj-control button.wj-applybutton:not(.wj-btn-default) {
    border-radius: 4px;
    padding: 6px 15px;
    text-decoration: none;
    font-weight: 700;
    font-size: 12px
}

.wj-header {
    background: #eee;
    color: #444;
    font-weight: 700
}

.wj-state-selected {
    background: #0085c7;
    color: #fff
}

.wj-state-multi-selected {
    background: #80adbf;
    color: #fff
}

.wj-state-disabled {
    opacity: .6;
    cursor: default;
    pointer-events: none
}

.wj-content {
    display: inline-block;
    border: 1px solid rgba(0, 0, 0, .2);
    border-radius: 4px;
    overflow: hidden
}

.wj-tooltip {
    box-sizing: border-box;
    position: absolute;
    pointer-events: none;
    max-width: 400px;
    padding: 4px 6px 3px 6px;
    background: #fff;
    color: #000;
    border: 1px solid rgba(0, 0, 0, .2);
    border-radius: 4px;
    box-shadow: 0 6px 13px rgba(0, 0, 0, .2)
}

.wj-tooltip.wj-error-tip {
    background: red;
    color: #fff;
    white-space: pre-line;
    font-weight: 700
}

.wj-dropdown-panel {
    box-shadow: 0 6px 13px rgba(0, 0, 0, .2)
}

.wj-content.wj-dropdown, .wj-content.wj-inputnumber, .wj-content.wj-inputmask, .wj-content.wj-calendar-outer, .wj-content.wj-pager {
    vertical-align: middle;
    display: inline-table;
    border-collapse: separate
}

.wj-control .wj-template {
    position: relative;
    display: table-cell
}

.wj-control .wj-template, .wj-control .wj-input {
    height: 100%
}

.wj-control .wj-input-group {
    display: table;
    border-collapse: separate;
    width: 100%;
    height: 100%
}

.wj-control .wj-input-group .wj-input-group-btn {
    display: table-cell;
    width: 1%;
    height: 100%;
    min-width: 26px;
    white-space: nowrap;
    vertical-align: top
}

.wj-control .wj-input-group .wj-input-group-btn:last-child:not(:first-child)>.wj-btn, .wj-viewer .wj-control .wj-input-group .wj-input-group-btn:last-child:not(:first-child)>.wj-applybutton {
    border-left: 1px solid rgba(0, 0, 0, .2)
}

.wj-control .wj-input-group .wj-input-group-btn:first-child:not(:last-child)>.wj-btn, .wj-viewer .wj-control .wj-input-group .wj-input-group-btn:first-child:not(:last-child)>.wj-applybutton {
    border-right: 1px solid rgba(0, 0, 0, .2)
}

.wj-control .wj-input-group .wj-form-control {
    display: table-cell;
    padding: 4px 8px;
    background: 0 0;
    color: inherit;
    border: 0;
    width: 100%;
    height: 100%;
    min-height: 2em
}

.wj-control .wj-btn-group {
    display: inline-block;
    border: 1px solid rgba(0, 0, 0, .2);
    border-radius: 4px;
    overflow: hidden
}

.wj-control .wj-btn-group>:not(:first-child) {
    border-left: 1px solid rgba(0, 0, 0, .2)
}

.wj-control .wj-btn-group>.wj-form-control {
    padding: 4px 6px 3px 6px;
    background: 0 0;
    border: 0
}

.wj-control .wj-btn-group>.wj-form-control:not(:first-child) {
    border-left: 1px solid rgba(0, 0, 0, .2)
}

.wj-control .wj-btn-group>.wj-btn, .wj-viewer .wj-control .wj-btn-group>.wj-applybutton, .wj-control .wj-input-group-btn>.wj-btn, .wj-viewer .wj-control .wj-input-group-btn>.wj-applybutton {
    height: 100%;
    min-height: 2em;
    background: #eee
}

.wj-control .wj-btn-group>.wj-btn:focus, .wj-viewer .wj-control .wj-btn-group>.wj-applybutton:focus, .wj-control .wj-btn-group>.wj-btn:hover, .wj-viewer .wj-control .wj-btn-group>.wj-applybutton:hover, .wj-control .wj-input-group-btn>.wj-btn:focus, .wj-viewer .wj-control .wj-input-group-btn>.wj-applybutton:focus, .wj-control .wj-input-group-btn>.wj-btn:hover, .wj-viewer .wj-control .wj-input-group-btn>.wj-applybutton:hover {
    background: #e1e1e1;
    transition-duration: .4s
}

.wj-control .wj-btn-group>.wj-btn.wj-btn-default, .wj-viewer .wj-control .wj-btn-group>.wj-btn-default.wj-applybutton, .wj-control .wj-input-group-btn>.wj-btn.wj-btn-default, .wj-viewer .wj-control .wj-input-group-btn>.wj-btn-default.wj-applybutton {
    background: 0 0;
    color: #000
}

.wj-control .wj-btn-group>.wj-btn.wj-btn-default:focus, .wj-viewer .wj-control .wj-btn-group>.wj-btn-default.wj-applybutton:focus, .wj-control .wj-btn-group>.wj-btn.wj-btn-default:hover, .wj-viewer .wj-control .wj-btn-group>.wj-btn-default.wj-applybutton:hover, .wj-control .wj-input-group-btn>.wj-btn.wj-btn-default:focus, .wj-viewer .wj-control .wj-input-group-btn>.wj-btn-default.wj-applybutton:focus, .wj-control .wj-input-group-btn>.wj-btn.wj-btn-default:hover, .wj-viewer .wj-control .wj-input-group-btn>.wj-btn-default.wj-applybutton:hover {
    background: #e1e1e1;
    transition-duration: .4s
}

.wj-control.wj-rtl .wj-input-group-btn:last-child:not(:first-child)>.wj-btn, .wj-viewer .wj-control.wj-rtl .wj-input-group-btn:last-child:not(:first-child)>.wj-applybutton {
    border-right: 1px solid rgba(0, 0, 0, .2);
    border-left: none
}

.wj-control.wj-rtl .wj-input-group-btn:first-child:not(:last-child)>.wj-btn, .wj-viewer .wj-control.wj-rtl .wj-input-group-btn:first-child:not(:last-child)>.wj-applybutton {
    border-left: 1px solid rgba(0, 0, 0, .2);
    border-right: none
}

.wj-control.wj-rtl .wj-btn-group>:not(:first-child) {
    border-right: 1px solid rgba(0, 0, 0, .2);
    border-left: none
}

.wj-control.wj-rtl .wj-btn-group>.wj-form-control:not(:first-child) {
    border-right: 1px solid rgba(0, 0, 0, .2);
    border-left: none
}

.wj-inputnumber input.wj-form-control {
    text-align: right
}

.wj-listbox {
    overflow: auto;
    cursor: default
}

.wj-listbox .wj-listbox-item {
    padding: 4px 6px 3px 6px
}

.wj-listbox .wj-listbox-item.wj-separator {
    height: 1px;
    margin: 3px 0;
    padding: 0;
    background: rgba(0, 0, 0, .1)
}

.wj-listbox .wj-listbox-item.wj-header {
    opacity: 1;
    position: sticky;
    top: 0
}

.wj-listbox .wj-listbox-item.wj-state-selected {
    transform: scale(1)
}

.wj-listbox .wj-listbox-item:not(.wj-state-selected):not(.wj-state-disabled):not(.wj-separator):hover {
    background: rgba(0, 0, 0, .05);
    transition-duration: .4s
}

.wj-listbox .wj-listbox-item label {
    font-weight: 400;
    margin: 0
}

.wj-listbox.wj-dropdown-panel>.wj-listbox {
    width: 100%;
    border: 0;
    background: inherit;
    color: inherit
}

.wj-calendar .wj-header {
    font-size: 90%
}

.wj-calendar .wj-day-today {
    font-weight: 700
}

.wj-calendar .wj-day-othermonth {
    opacity: .65
}

.wj-calendar .wj-calendar-outer {
    display: block;
    padding: 10px;
    width: 100%;
    height: auto;
    cursor: default
}

.wj-calendar .wj-calendar-header {
    display: block;
    padding: 0 0 15px 0;
    width: 100%
}

.wj-calendar .wj-calendar-header .wj-month-select {
    display: inline-block;
    cursor: pointer
}

.wj-calendar .wj-calendar-header .wj-btn-group {
    float: right;
    margin-bottom: 7px
}

.wj-calendar .wj-calendar-header .wj-btn-group .wj-btn, .wj-calendar .wj-calendar-header .wj-btn-group .wj-viewer .wj-control .wj-applybutton, .wj-viewer .wj-control .wj-calendar .wj-calendar-header .wj-btn-group .wj-applybutton {
    padding: 2px 8px 0 8px
}

.wj-calendar .wj-calendar-month {
    width: 100%;
    border-collapse: collapse;
    font: inherit
}

.wj-calendar .wj-calendar-month td {
    width: 14.29%
}

.wj-calendar .wj-calendar-year {
    width: 100%;
    border-collapse: collapse;
    font: inherit
}

.wj-calendar .wj-calendar-year td {
    width: 25%
}

.wj-calendar .wj-calendar-year tr:not(.wj-header) td {
    height: 3em
}

.wj-calendar td {
    text-align: center;
    padding: 4px 6px 3px 6px;
    border: 0
}

.wj-calendar.wj-dropdown-panel {
    width: 23em
}

.wj-calendar.wj-rtl .wj-calendar-header .wj-btn-group {
    float: left
}

.wj-calendar.wj-rtl .wj-calendar-header .wj-btn-group .wj-btn .wj-glyph-right, .wj-calendar.wj-rtl .wj-calendar-header .wj-btn-group .wj-viewer .wj-control .wj-applybutton .wj-glyph-right, .wj-viewer .wj-control .wj-calendar.wj-rtl .wj-calendar-header .wj-btn-group .wj-applybutton .wj-glyph-right, .wj-calendar.wj-rtl .wj-calendar-header .wj-btn-group .wj-btn .wj-glyph-left, .wj-calendar.wj-rtl .wj-calendar-header .wj-btn-group .wj-viewer .wj-control .wj-applybutton .wj-glyph-left, .wj-viewer .wj-control .wj-calendar.wj-rtl .wj-calendar-header .wj-btn-group .wj-applybutton .wj-glyph-left {
    transform: scale(-1, 1)
}

.wj-dropdown-panel .wj-calendar-outer {
    border: 0;
    background: 0 0
}

.wj-dropdown-panel .wj-calendar-outer>.wj-content {
    display: block
}

.wj-yearpicker {
    max-height: 12em;
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    width: 4em;
    text-align: center
}

.wj-yearpicker .wj-listbox-item {
    text-align: center;
    display: inline-block;
    width: 4em
}

.wj-colorpicker {
    width: 420px;
    height: 200px;
    padding: 4px
}

.wj-colorpicker .wj-colorbox {
    position: relative;
    width: 100%;
    height: 100%;
    cursor: pointer;
    box-shadow: 0 1px 3px rgba(0, 0, 0, .12), 0 1px 2px rgba(0, 0, 0, .24)
}

.wj-inputcolor .wj-input-group input.wj-form-control {
    padding-left: 24px
}

.wj-inputcolor .wj-inputcolorbox {
    position: absolute;
    left: 6px;
    top: 6px;
    bottom: 6px;
    width: 12px;
    border: 1px solid #000
}

.wj-inputcolor.wj-rtl .wj-input-group input.wj-form-control {
    padding-left: 4px;
    padding-right: 24px
}

.wj-inputcolor.wj-rtl .wj-inputcolorbox {
    left: auto;
    right: 6px
}

.wj-listbox.wj-dropdown-panel .wj-autocomplete-match {
    font-weight: 700
}

.wj-multi-autocomplete {
    width: 100%;
    padding: 5px
}

.wj-multi-autocomplete .wj-input {
    float: left
}

.wj-multi-autocomplete .wj-input-group .wj-form-control {
    margin-top: 5px;
    height: auto
}

.wj-multi-autocomplete .wj-token {
    background: #eee;
    color: #000;
    border: 1px solid rgba(0, 0, 0, .2);
    border-radius: 4px;
    margin: 5px;
    vertical-align: middle;
    float: left;
    cursor: default
}

.wj-multi-autocomplete .wj-token:focus, .wj-multi-autocomplete .wj-token:hover, .wj-multi-autocomplete .wj-token.wj-token-active {
    background: #e1e1e1;
    transition-duration: .4s
}

.wj-multi-autocomplete .wj-token .wj-token-label {
    padding: 4px 6px 3px 6px;
    display: inline-block;
    overflow: hidden;
    text-overflow: ellipsis;
    vertical-align: middle;
    max-width: 500px
}

.wj-multi-autocomplete .wj-token .wj-token-close {
    padding: 4px 6px 3px 6px;
    color: #000;
    vertical-align: middle;
    cursor: pointer;
    text-decoration: none
}

.wj-multi-autocomplete .wj-token .wj-token-close:hover, .wj-multi-autocomplete .wj-token .wj-token-close:focus {
    opacity: .5;
    transition-duration: .4s
}

.wj-multi-autocomplete .wj-token-helper {
    position: absolute;
    left: -10000px
}

.wj-popup {
    background: #fff;
    box-shadow: 0 6px 13px rgba(0, 0, 0, .2);
    margin: 2px 0
}

.wj-popup .wj-dialog-header {
    width: 100%;
    background: #eee;
    color: #444;
    font-weight: 700;
    font-size: 120%;
    padding: 1em
}

.wj-popup .wj-dialog-body {
    padding: 1em
}

.wj-popup .wj-dialog-footer {
    text-align: right;
    margin-top: 1em;
    padding: 1em
}

.wj-popup-backdrop {
    position: fixed;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    background: rgba(0, 0, 0, .5)
}

.wj-menu .wj-form-control {
    cursor: pointer
}

.wj-listbox.wj-menu-items .wj-listbox-item:not(.wj-state-disabled):not(.wj-separator) {
    cursor: pointer
}

.wj-listbox.wj-menu-items .wj-listbox-item.wj-subitems {
    position: relative
}

.wj-listbox.wj-menu-items .wj-listbox-item.wj-subitems:after {
    content: '';
    position: absolute;
    top: 50%;
    transform: translate(0, -50%);
    opacity: .75;
    border-top: .4em solid transparent;
    border-bottom: .4em solid transparent;
    border-left: .5em solid
}

.wj-listbox.wj-menu-items:not(.wj-rtl) .wj-listbox-item.wj-subitems {
    padding-right: 1.8em
}

.wj-listbox.wj-menu-items:not(.wj-rtl) .wj-listbox-item.wj-subitems:after {
    right: .55em
}

.wj-listbox.wj-menu-items.wj-rtl .wj-listbox-item.wj-subitems {
    padding-left: 1.8em
}

.wj-listbox.wj-menu-items.wj-rtl .wj-listbox-item.wj-subitems:after {
    left: .55em;
    transform: translate(0, -50%) scale(-1, 1)
}

.wj-pager .wj-input-group .wj-input-group-btn>.wj-btn, .wj-pager .wj-input-group .wj-viewer .wj-control .wj-input-group-btn>.wj-applybutton, .wj-viewer .wj-control .wj-pager .wj-input-group .wj-input-group-btn>.wj-applybutton {
    min-width: 40px
}

.wj-pager .wj-input-group .wj-form-control {
    text-align: center;
    border-left: 1px solid rgba(0, 0, 0, .2);
    border-right: 1px solid rgba(0, 0, 0, .2)
}

.wj-tabpanel {
    display: block
}

.wj-tabpanel>div>.wj-tabpanes {
    overflow: auto;
    border-top: 1px solid #ddd;
    user-select: text;
    -webkit-user-select: text;
    -moz-user-select: text;
    -ms-user-select: text
}

.wj-tabpanel>div>.wj-tabpanes>.wj-tabpane:not(.wj-state-active) {
    display: none
}

.wj-tabpanel>div>.wj-tabheaders>.wj-tabheader {
    position: relative;
    cursor: pointer;
    display: inline-block;
    text-align: center;
    padding: 8px 12px;
    text-decoration: none;
    color: inherit;
    font-weight: 700;
    text-transform: uppercase
}

.wj-tabpanel>div>.wj-tabheaders>.wj-tabheader.wj-state-active {
    background: #fff;
    color: #0085c7
}

.wj-tabpanel>div>.wj-tabheaders>.wj-tabheader:not(.wj-state-active):not(.wj-state-disabled):focus {
    background: rgba(0, 0, 0, .1)
}

.wj-tabpanel>div>.wj-tabheaders>.wj-tabheader:after {
    content: '';
    position: absolute;
    background: #0085c7;
    height: 2.5px;
    left: 45%;
    width: 10%;
    bottom: 0;
    visibility: hidden
}

.wj-tabpanel>div>.wj-tabheaders>.wj-tabheader.wj-state-active:after {
    left: 0;
    width: 100%;
    visibility: visible;
    transition-duration: .2s
}

.wj-treeview {
    width: 100%;
    overflow: auto;
    border: 0
}

.wj-treeview .wj-nodelist {
    display: block;
    position: relative
}

.wj-treeview .wj-nodelist .wj-nodelist {
    padding-left: 1em
}

.wj-treeview .wj-node {
    cursor: pointer;
    white-space: nowrap;
    padding: 3px 10px
}

.wj-treeview .wj-node:before {
    content: '';
    position: relative;
    display: inline-block;
    border-top: .5em solid;
    border-right: .45em solid transparent;
    border-left: .45em solid transparent;
    top: -1px;
    margin-right: 4px;
    opacity: .75;
    transition: all .3s cubic-bezier(.4, 0, .2, 1)
}

.wj-treeview .wj-node.wj-state-selected {
    color: inherit;
    background: rgba(0, 0, 0, .05)
}

.wj-treeview .wj-node.wj-state-collapsed:before, .wj-treeview .wj-node.wj-state-collapsing:before {
    transform: rotate(-90deg);
    transition: all .3s cubic-bezier(.4, 0, .2, 1)
}

.wj-treeview .wj-node.wj-state-collapsed.wj-state-loading:before {
    animation-name: wjLazyNodeLoading;
    animation-duration: .6s;
    animation-direction: alternate;
    animation-iteration-count: infinite
}

.wj-treeview .wj-node.wj-state-empty:before {
    visibility: hidden
}

.wj-treeview .wj-node.wj-state-collapsed+.wj-nodelist {
    display: none
}

.wj-treeview .wj-node img {
    padding-right: 4px
}

.wj-treeview .wj-node .wj-node-check {
    margin-right: 4px
}

.wj-treeview .wj-node .wj-node-text {
    display: inline-block
}

.wj-treeview .wj-marker {
    position: absolute;
    background: #0085c7;
    opacity: .5;
    pointer-events: none
}

.wj-treeview.wj-state-loading * {
    cursor: wait
}

.wj-treeview.wj-rtl .wj-nodelist .wj-nodelist {
    padding-left: unset;
    padding-right: 1em
}

.wj-treeview.wj-rtl .wj-nodelist .wj-node:before {
    margin-right: unset;
    margin-left: 4px
}

.wj-treeview.wj-rtl .wj-nodelist .wj-node.wj-state-collapsed:before, .wj-treeview.wj-rtl .wj-nodelist .wj-node.wj-state-collapsing:before {
    transform: rotate(90deg)
}

.wj-treeview.wj-rtl .wj-node img {
    padding-right: unset;
    padding-left: 4px
}

.wj-treeview.wj-rtl .wj-node .wj-node-check {
    margin-right: unset;
    margin-left: 4px
}

@keyframes wjLazyNodeLoading {
    0% {
        opacity: 1;
        transform: scale(1.1)
    }

    to {
        opacity: .5;
        transform: scale(.5)
    }
}

.wj-flexgrid {
    width: 100%;
    cursor: default;
    overflow: hidden
}

.wj-flexgrid .wj-row {
    position: absolute;
    top: 0
}

.wj-flexgrid .wj-colheaders .wj-header.wj-state-multi-selected {
    border-bottom: 2px solid #0085c7
}

.wj-flexgrid .wj-rowheaders .wj-header.wj-state-multi-selected {
    border-right: 2px solid #0085c7
}

.wj-flexgrid .wj-frozen-clone .wj-cell {
    pointer-events: auto
}

.wj-flexgrid .wj-frozen-clone .wj-cell a {
    display: inline-block
}

.wj-flexgrid .wj-marquee {
    position: absolute;
    border: 2px solid #0085c7
}

.wj-flexgrid .wj-marker {
    position: absolute;
    background: #0085c7;
    opacity: .5;
    pointer-events: none;
    z-index: 1000
}

.wj-flexgrid .wj-state-sticky .wj-header {
    opacity: .75
}

.wj-flexgrid .wj-grid-editor {
    width: 100%;
    height: 100%;
    border: 0;
    margin: 0;
    padding: 0;
    background: inherit;
    color: inherit;
    overflow: hidden;
    resize: none
}

.wj-flexgrid .wj-cell .wj-btn.wj-btn-glyph, .wj-flexgrid .wj-cell .wj-viewer .wj-control .wj-btn-glyph.wj-applybutton, .wj-viewer .wj-control .wj-flexgrid .wj-cell .wj-btn-glyph.wj-applybutton {
    padding: 0 4px;
    min-width: 1.5em;
    color: inherit;
    background: 0 0;
    opacity: .6
}

.wj-flexgrid .wj-cell .wj-btn.wj-btn-glyph:hover, .wj-flexgrid .wj-cell .wj-viewer .wj-control .wj-btn-glyph.wj-applybutton:hover, .wj-viewer .wj-control .wj-flexgrid .wj-cell .wj-btn-glyph.wj-applybutton:hover, .wj-flexgrid .wj-cell .wj-btn.wj-btn-glyph:focus, .wj-flexgrid .wj-cell .wj-viewer .wj-control .wj-btn-glyph.wj-applybutton:focus, .wj-viewer .wj-control .wj-flexgrid .wj-cell .wj-btn-glyph.wj-applybutton:focus {
    opacity: 1;
    transition-duration: .4s
}

.wj-flexgrid .wj-cell .wj-btn.wj-btn-glyph.wj-right, .wj-flexgrid .wj-cell .wj-viewer .wj-control .wj-btn-glyph.wj-right.wj-applybutton, .wj-viewer .wj-control .wj-flexgrid .wj-cell .wj-btn-glyph.wj-right.wj-applybutton {
    position: absolute;
    right: 4px;
    top: 0;
    bottom: 0
}

.wj-flexgrid .wj-cell .wj-btn.wj-btn-glyph.wj-elem-detail, .wj-flexgrid .wj-cell .wj-viewer .wj-control .wj-btn-glyph.wj-elem-detail.wj-applybutton, .wj-viewer .wj-control .wj-flexgrid .wj-cell .wj-btn-glyph.wj-elem-detail.wj-applybutton {
    min-width: 1em;
    padding: 0
}

.wj-flexgrid .wj-cell .wj-btn.wj-btn-glyph>span, .wj-flexgrid .wj-cell .wj-viewer .wj-control .wj-btn-glyph.wj-applybutton>span, .wj-viewer .wj-control .wj-flexgrid .wj-cell .wj-btn-glyph.wj-applybutton>span {
    opacity: 1
}

.wj-flexgrid.wj-rtl .wj-cell {
    border-left: 1px solid rgba(0, 0, 0, .2);
    border-right: none
}

.wj-flexgrid.wj-rtl .wj-cell .wj-btn.wj-btn-glyph.wj-right, .wj-flexgrid.wj-rtl .wj-cell .wj-viewer .wj-control .wj-btn-glyph.wj-right.wj-applybutton, .wj-viewer .wj-control .wj-flexgrid.wj-rtl .wj-cell .wj-btn-glyph.wj-right.wj-applybutton {
    left: 2px;
    right: auto
}

.wj-flexgrid.wj-rtl .wj-frozen-col {
    border-left: 1px solid #a2a2a2;
    border-right: none
}

.wj-cell {
    position: absolute;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    padding: 4px 6px 3px 6px;
    border-right: 1px solid rgba(0, 0, 0, .2);
    border-bottom: 1px solid rgba(0, 0, 0, .2);
    background: #fff;
    outline: 0
}

.wj-cell.wj-alt {
    background: #f7f7f7
}

.wj-cell.wj-header {
    background: #eee;
    color: #444;
    font-weight: 700
}

.wj-cell.wj-header.wj-state-multi-selected {
    background: #e1e1e1;
    color: #444;
    font-weight: 700
}

.wj-cell.wj-frozen-row {
    border-bottom: 1px solid #a2a2a2
}

.wj-cell.wj-frozen-col {
    border-right: 1px solid #a2a2a2
}

.wj-cell.wj-group {
    background: #e1e1e1;
    color: #444
}

.wj-cell.wj-state-invalid {
    border: 1.2px solid red
}

.wj-cell.wj-state-invalid.wj-header {
    background: #ffb4b4
}

.wj-cell.wj-state-invalid:not(.wj-header):after {
    position: absolute;
    content: '';
    width: 0;
    right: 0;
    top: -6px;
    border: 6px solid transparent;
    border-right-color: red
}

.wj-cell.wj-header.wj-state-dragsrc {
    opacity: .5
}

.wj-cell.wj-wrap {
    white-space: normal;
    text-overflow: clip
}

.wj-cell.wj-multiline {
    white-space: pre;
    text-overflow: clip
}

.wj-cell.wj-wrap.wj-multiline {
    white-space: pre-wrap;
    text-overflow: clip
}

.wj-cell.wj-align-right {
    text-align: right
}

.wj-cell.wj-align-center {
    text-align: center
}

.wj-cell.wj-align-justify {
    text-align: justify
}

.wj-cell.wj-align-justify-all {
    text-align: justify;
    text-align-last: justify;
    text-justify: distribute
}

.wj-cells .wj-cell.wj-state-selected {
    background: #0085c7;
    color: #fff
}

.wj-cells .wj-cell.wj-state-multi-selected {
    background: #80adbf;
    color: #fff
}

.wj-flexgrid .wj-cell.wj-filter-on .wj-elem-filter {
    opacity: .9
}

.wj-flexgrid .wj-cell.wj-filter-off .wj-elem-filter {
    opacity: .5
}

.wj-flexgrid .wj-cell.wj-filter-on, .wj-flexgrid .wj-cell.wj-filter-off {
    padding-right: 1.6em
}

.wj-flexgrid.wj-rtl .wj-cell.wj-filter-on, .wj-flexgrid.wj-rtl .wj-cell.wj-filter-off {
    padding-left: 1.6em;
    padding-right: 2px
}

.wj-columnfiltereditor {
    padding: 10px;
    min-width: 230px;
    max-width: 400px
}

.wj-columnfiltereditor .wj-filtertype {
    text-align: right;
    margin: 14px 0;
    font-size: 12px
}

.wj-columnfiltereditor .wj-control {
    margin-bottom: 6px;
    width: 100%
}

.wj-columnfiltereditor .wj-valuefilter-editor, .wj-columnfiltereditor .wj-conditionfilter-editor {
    margin-top: 1em
}

.wj-columnfiltereditor .wj-listbox {
    height: 150px
}

.wj-columnfiltereditor .wj-listbox .wj-listbox-item {
    max-width: 25em
}

.wj-columnfiltereditor .wj-listbox .wj-listbox-item label {
    white-space: nowrap
}

.wj-columnfiltereditor .wj-listbox:not(.wj-state-focused) .wj-listbox-item.wj-state-selected {
    color: inherit;
    background: inherit;
    transition: all 300ms
}

.wj-columnfiltereditor a {
    color: inherit;
    font-size: inherit;
    font-weight: 700
}

.wj-columnfiltereditor a.wj-state-disabled {
    text-decoration: none
}

.wj-grouppanel {
    display: block;
    background: #e1e1e1;
    padding: 15px
}

.wj-grouppanel .wj-groupmarker {
    margin-right: 10px;
    padding: 6px 16px;
    border: 1px solid rgba(0, 0, 0, .2);
    cursor: pointer
}

.wj-grouppanel .wj-groupmarker:hover {
    background: #e1e1e1
}

.wj-grouppanel .wj-groupmarker .wj-glyph-drag {
    margin: 0 10px 0 0
}

.wj-grouppanel .wj-groupmarker .wj-filter {
    margin: 0 0 0 10px;
    cursor: pointer;
    opacity: .5
}

.wj-grouppanel .wj-groupmarker .wj-filter:hover, .wj-grouppanel .wj-groupmarker .wj-filter.wj-filter-on {
    opacity: 1
}

.wj-grouppanel .wj-groupmarker .wj-remove {
    cursor: pointer;
    opacity: .5;
    padding: 12px 0 12px 12px
}

.wj-grouppanel .wj-groupmarker .wj-remove:hover {
    opacity: 1
}

.wj-grouppanel .wj-groupmarker span {
    opacity: .5
}

.wj-grouppanel .wj-groupmarker span:hover {
    opacity: 1
}

.wj-grouppanel.wj-rtl .wj-groupmarker .wj-glyph-drag {
    margin: 0 0 0 10px
}

.wj-grouppanel.wj-rtl .wj-groupmarker .wj-filter {
    margin: 0 10px 0 0
}

.wj-grouppanel.wj-rtl .wj-groupmarker .wj-remove {
    padding: 12px 12px 12px 0
}

.wj-flexchart {
    height: 80vh;
    padding: 15px 10px;
    margin-bottom: 12px;
    background: #fff;
    border: 0px solid #e4e4e4;
    display: block;
    background: 0 0
}

.wj-flexchart .wj-header .wj-title {
    font-size: 16pt;
    fill: #666;
    font-weight: 400
}

.wj-flexchart .wj-footer .wj-title {
    fill: #666;
    font-weight: 400
}

.wj-flexchart .wj-label, .wj-flexchart .wj-data-label {
    fill: #666
}

.wj-flexchart .wj-data-label-border {
    stroke: rgba(128, 128, 128, .5)
}

.wj-flexchart .wj-data-label-line {
    stroke: gray
}

.wj-flexchart .wj-axis-x .wj-title, .wj-flexchart .wj-axis-y .wj-title, .wj-flexchart .wj-legend .wj-title {
    font-style: italic
}

.wj-flexchart .wj-axis-x .wj-line {
    stroke: #aaa;
    stroke-width: 1px
}

.wj-flexchart .wj-gridline, .wj-flexchart .wj-gridline-minor {
    stroke: #666;
    stroke-width: 1px;
    opacity: .75
}

.wj-flexchart .wj-gridline-minor {
    opacity: .25
}

.wj-flexchart .wj-tick, .wj-flexchart .wj-tick-minor {
    stroke: #aaa;
    stroke-width: 1px
}

.wj-flexchart .wj-state-selected {
    stroke-width: 3px;
    stroke-dasharray: 6;
    stroke-linecap: square
}

.wj-flexchart.wj-flexchart-touch-disabled {
    touch-action: none;
    -ms-touch-action: none
}

.wj-flexchart .wj-chart-linemarker {
    background: rgba(128, 128, 128, .3);
    position: absolute;
    padding: 2px
}

.wj-flexchart .wj-chart-linemarker .wj-chart-linemarker-hline {
    height: 2px;
    background: gray;
    position: absolute;
    touch-action: none;
    -ms-touch-action: none
}

.wj-flexchart .wj-chart-linemarker .wj-chart-linemarker-vline {
    width: 2px;
    background: gray;
    position: absolute;
    touch-action: none;
    -ms-touch-action: none
}

.wj-flexchart .wj-chart-linemarker-container {
    position: relative
}

.wj-flexchart.wj-chart-linemarker-draggable, .wj-flexchart .wj-chart-linemarker .wj-chart-linemarker-draggable {
    cursor: move
}

@media (max-width:1025px) {
    .wj-flexchart .wj-state-selected {
        stroke-width: 2px;
        stroke-dasharray: 4
    }
}

@media (max-width:767px) {
    wj-flexchart .wj-state-selected {
        stroke-width: 1px;
        stroke-dasharray: 3
    }
}

.wj-treemap .wj-data-label {
    fill: #fff
}

.wj-flexchart .wj-chart-rangeslider {
    position: absolute;
    touch-action: none;
    -ms-touch-action: none
}

.wj-flexchart .wj-chart-rangeslider button {
    position: absolute;
    text-align: center;
    vertical-align: middle;
    padding: 0;
    line-height: 16px;
    border-radius: 2px
}

.wj-flexchart .wj-chart-rangeslider .wj-rangeslider-content {
    background: #eaeaea;
    height: 100%;
    position: relative
}

.wj-flexchart .wj-chart-rangeslider .wj-rangeslider-rangehandle {
    position: absolute;
    text-align: center;
    vertical-align: middle;
    background: #bdbdbd;
    height: 100%;
    width: 100%
}

.wj-flexchart .wj-chart-rangeslider .wj-rangeslider-minhandle, .wj-flexchart .wj-chart-rangeslider .wj-rangeslider-maxhandle {
    border: 1px solid gray;
    display: block;
    position: absolute;
    border-radius: 4px
}

.wj-flexchart .wj-chart-rangeslider .wj-rangeslider-handle-active {
    z-index: 2
}

.wj-flexchart .wj-chart-rangeslider .wj-glyph-left {
    border-top: 5px solid transparent;
    border-right: 4px solid;
    border-bottom: 4px solid transparent;
    margin-right: 2px
}

.wj-flexchart .wj-chart-rangeslider .wj-glyph-right {
    border-bottom: 5px solid transparent;
    border-left: 4px solid;
    border-top: 4px solid transparent;
    margin-left: 2px
}

.wj-flexchart .wj-chart-rangeslider .wj-glyph-down {
    border-top: 5px solid;
    border-right: 4px solid transparent;
    border-left: 4px solid transparent;
    margin-bottom: 3px
}

.wj-flexchart .wj-chart-rangeslider .wj-glyph-up {
    border-right: 4px solid transparent;
    border-bottom: 5px solid;
    border-left: 4px solid transparent;
    margin-bottom: 4px
}

.wj-flexchart .wj-chart-hrangeslider .wj-rangeslider-minhandle, .wj-flexchart .wj-chart-hrangeslider .wj-rangeslider-maxhandle {
    background: #bdbdbd;
    cursor: ew-resize;
    height: 22px;
    margin-top: -2px;
    width: 14px
}

.wj-flexchart .wj-chart-hrangeslider .wj-rangeslider-decbtn {
    left: 0
}

.wj-flexchart .wj-chart-hrangeslider .wj-rangeslider-incbtn {
    right: 0
}

.wj-flexchart .wj-chart-hrangeslider button {
    width: 16px;
    height: 100%
}

.wj-flexchart .wj-chart-vrangeslider .wj-rangeslider-minhandle, .wj-flexchart .wj-chart-vrangeslider .wj-rangeslider-maxhandle {
    background: #bdbdbd;
    cursor: ns-resize;
    width: 22px;
    margin-left: -2px;
    height: 14px
}

.wj-flexchart .wj-chart-vrangeslider .wj-rangeslider-decbtn {
    bottom: 0
}

.wj-flexchart .wj-chart-vrangeslider .wj-rangeslider-incbtn {
    top: 0
}

.wj-flexchart .wj-chart-vrangeslider button {
    height: 16px;
    width: 100%
}

.wj-flexchart .wj-chart-rangeselector-container {
    position: relative
}

.wj-flexchart .wj-chart-rangeselector-container .wj-chart-rangeslider, .wj-flexchart .wj-chart-rangeselector-container .wj-rangeslider-content {
    background: 0 0;
    border-color: transparent
}

.wj-flexchart .wj-chart-rangeselector-container .wj-rangeslider-rangehandle {
    opacity: .3
}

.wj-flexchart .wj-chart-rangeselector-container .wj-rangeslider-minhandle, .wj-flexchart .wj-chart-rangeselector-container .wj-rangeslider-maxhandle {
    background: 0 0;
    opacity: .6;
    border: 2px solid gray;
    border-radius: .5em
}

.wj-flexchart .wj-chart-rangeselector-container .wj-chart-hrangeslider .wj-rangeslider-minhandle, .wj-flexchart .wj-chart-rangeselector-container .wj-chart-hrangeslider .wj-rangeslider-maxhandle {
    height: 20px;
    width: 20px;
    border-radius: 50%;
    top: 0;
    bottom: 0;
    margin: auto;
    border: 1px solid rgba(128, 128, 128, .75);
    color: gray;
    background: #d3d3d3;
    opacity: 1
}

.wj-flexchart .wj-chart-rangeselector-container .wj-chart-hrangeslider .wj-rangeslider-minhandle:after, .wj-flexchart .wj-chart-rangeselector-container .wj-chart-hrangeslider .wj-rangeslider-maxhandle:after {
    content: "\2551";
    text-align: center;
    width: 100%;
    display: inline-block;
    position: absolute;
    margin: 0;
    top: 50%;
    transform: translateY(-55%);
    opacity: .75;
    font-size: 10px
}

.wj-flexchart .wj-chart-rangeselector-container .wj-chart-vrangeslider .wj-rangeslider-minhandle, .wj-flexchart .wj-chart-rangeselector-container .wj-chart-vrangeslider .wj-rangeslider-maxhandle {
    height: 20px;
    width: 20px;
    left: 50%;
    border-radius: 50%;
    border: 1px solid rgba(128, 128, 128, .75);
    top: 0;
    bottom: 0;
    color: gray;
    background: #d3d3d3;
    opacity: 1
}

.wj-flexchart .wj-chart-rangeselector-container .wj-chart-vrangeslider .wj-rangeslider-minhandle:after, .wj-flexchart .wj-chart-rangeselector-container .wj-chart-vrangeslider .wj-rangeslider-maxhandle:after {
    content: "\2550";
    text-align: center;
    height: 100%;
    display: inline-block;
    position: absolute;
    margin: 0;
    left: 50%;
    transform: translate(-50%, 15%);
    opacity: .75;
    font-size: 12px
}

.wj-flexchart .wj-chart-rangeselector-container .wj-chart-rangeslider .wj-rangeslider-minhandle.wj-rangeslider-handle-active, .wj-flexchart .wj-chart-rangeselector-container .wj-chart-rangeslider .wj-rangeslider-maxhandle.wj-rangeslider-handle-active {
    background: rgba(136, 189, 230, .7)
}

.wj-flexchart .wj-rangeselector .wj-scroller-center {
    background: rgba(128, 128, 128, .1);
    position: absolute;
    display: block;
    touch-action: none;
    -ms-touch-action: none
}

.wj-flexchart.wj-panable {
    cursor: pointer
}

.wj-flexchart .wj-zoom {
    visibility: hidden;
    position: relative
}

.wj-flexchart .wj-zoom-overlay {
    background: rgba(128, 128, 128, .2);
    position: absolute;
    display: block;
    touch-action: none;
    -ms-touch-action: none
}

.wj-flexchart .wj-block-other-interaction {
    display: none
}

.wj-gauge {
    background: 0 0
}

.wj-gauge:not(.wj-state-readonly) {
    touch-action: none
}

.wj-gauge .wj-face path {
    fill: #f5f5f5;
    stroke: #cbcbcb;
    stroke-width: 1px;
    stroke-linejoin: round
}

.wj-gauge .wj-pointer {
    fill: #0085c7
}

.wj-gauge .wj-ticks {
    stroke-width: 2px;
    stroke: #fff
}

.wj-gauge .wj-min, .wj-gauge .wj-max {
    font-size: 60%;
    opacity: .5
}

.wj-gauge text {
    fill: currentColor
}

.wj-gauge svg {
    overflow: visible
}

.wj-radialgauge .wj-value {
    font-size: 150%
}

.wj-lineargauge {
    height: 1.2em
}

.wj-flexsheet {
    background: #a9a9a9;
    width: 100%;
    height: 100%
}

.wj-flexsheet .wj-content {
    outline: 0;
    border-color: #ccc;
    border-radius: 0
}

.wj-flexsheet .wj-header-row {
    background: #96abb4 !important;
    color: #fff !important
}

.wj-flexsheet .wj-state-multi-selected {
    background: #e6e6e6;
    color: #222
}

.wj-flexsheet .wj-cell.wj-state-multi-selected {
    border-right: 1px solid #bbb;
    border-bottom: 1px solid #bbb
}

.wj-flexsheet .wj-state-selected .wj-flexsheet-formula-description {
    color: #fff
}

.wj-flexsheet .wj-tabholder {
    width: 100%;
    min-width: 100px;
    background: #b8b8b8
}

.wj-flexsheet .wj-tabholder span.wj-sheet-icon {
    margin: 0 14px
}

.wj-flexsheet .wj-tabholder div.wj-sheet-page.wj-btn-group {
    border: 0
}

.wj-flexsheet .wj-tabholder div.wj-sheet-page.wj-btn-group>button {
    padding: 1px 0 0 0;
    border: 0;
    background: #e4e5e8;
    color: #727275;
    min-height: 0
}

.wj-flexsheet .wj-tabholder div.wj-sheet-page.wj-btn-group>button:hover {
    background: #d2d3d8
}

.wj-flexsheet .wj-tabholder .wj-sheet-tab ul {
    display: inline-block;
    margin: 0;
    padding: 0;
    float: left
}

.wj-flexsheet .wj-tabholder .wj-sheet-tab ul li {
    float: left;
    display: block;
    padding-right: 20px;
    padding-left: 20px;
    cursor: pointer;
    margin-top: 1px;
    border-left: 1px solid #b8b8b8;
    min-height: 20px;
    background: #d2d3d8;
    text-align: center;
    padding-top: 2px;
    font-size: 11px;
    color: #727275
}

.wj-flexsheet .wj-tabholder .wj-sheet-tab ul li.active {
    border-top-color: transparent;
    background: #fff;
    cursor: default;
    height: 100%;
    margin-top: 1px;
    border-left: 1px solid #b8b8b8;
    font-weight: 700
}

.wj-flexsheet .wj-tabholder .wj-sheet-tab ul li.hidden {
    display: none
}

.wj-flexsheet .wj-tabholder .wj-sheet-tab ul li:not(.active):hover {
    background: #aabcd6;
    color: #fff;
    cursor: pointer
}

.wj-flexsheet .wj-tabholder .wj-sheet-tab ul li .wj-new-sheet:hover {
    background: #0085c7
}

.wj-flexsheet .wj-tabholder .wj-new-sheet {
    padding: 0;
    width: 32px;
    height: 20px;
    font-size: 11px;
    text-align: center;
    background: #668eb9;
    color: #fff
}

.wj-flexsheet .wj-tabholder .wj-new-sheet .wj-sheet-icon {
    color: #727275;
    margin-top: 5px
}

.wj-flexsheet .wj-tabholder .wj-sheet>div {
    display: block;
    float: left
}

.wj-flexsheet .wj-flexchart {
    background: #fff;
    border: 1px solid rgba(0, 0, 0, .2)
}

.wj-flexsheet.wj-rtl div.wj-sheet>div {
    float: right
}

.wj-flexsheet-formula-list {
    margin: 0;
    padding: 0;
    background: #fff;
    border: 1px solid rgba(0, 0, 0, .2);
    font-family: arial, sans-serif;
    font-size: 12px;
    line-height: 22px;
    position: absolute;
    width: 300px;
    z-index: 2001;
    box-shadow: 0 2px 4px rgba(0, 0, 0, .2)
}

.wj-flexsheet-formula-list .wj-flexsheet-formula-name {
    color: #222;
    font-size: 13px;
    font-family: inconsolata, monospace, arial, sans, sans-serif;
    margin: -2px 0
}

.wj-flexsheet-formula-list .wj-flexsheet-formula-description {
    color: #666;
    display: block;
    font-size: 11px;
    margin: -2px 0;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap
}

.wj-flexsheet-formula-list .wj-listbox-item.wj-state-selected>* {
    color: #fff
}

.wj-flexsheet-context-menu {
    background: #fff;
    border: thin solid gray;
    cursor: default;
    padding: 3px 0
}

.wj-flexsheet-context-menu .wj-context-menu-item {
    padding: 3px 10px
}

.wj-flexsheet-context-menu .wj-context-menu-item:not(.wj-context-menu-item-selected):hover {
    background: rgba(0, 0, 0, .1)
}

.wj-flexsheet-context-menu .wj-context-menu-item-selected {
    background: #0085c7;
    color: #fff
}

.wj-flexsheet-smart-tag {
    background: #fff;
    border: 1px solid #ababab;
    position: absolute;
    padding: 0;
    margin: 0;
    height: 18px;
    outline: 0
}

.wj-flexsheet-smart-tag-active {
    background: #9fd5b7;
    border: 1px solid #ababab
}

.wj-flexsheet-fill-menu {
    background: #fff;
    border: 1px solid #9fd5b7;
    position: absolute;
    width: 200px;
    padding: 3px 0
}

.wj-flexsheet-fill-menu .wj-flexsheet-fill-menu-item {
    padding: 3px 10px
}

.wj-flexsheet-fill-menu .wj-flexsheet-fill-menu-item-active {
    background: #0085c7;
    color: #fff
}

.wj-flexsheet-fill-menu .wj-flexsheet-fill-menu-item:not(.wj-flexsheet-fill-menu-item-active):hover {
    background: rgba(0, 0, 0, .1)
}

.wj-multirow .wj-cell.wj-record-end:not(.wj-header) {
    border-bottom-color: #787878
}

.wj-multirow .wj-cell.wj-group-end {
    border-right-color: #787878
}

.wj-multirow .wj-cell.wj-center-vert {
    display: table;
    table-layout: fixed;
    padding-top: 0;
    padding-bottom: 0
}

.wj-multirow .wj-cell.wj-center-vert div {
    display: table-cell;
    vertical-align: middle;
    overflow: inherit;
    text-overflow: inherit
}

.wj-multirow .wj-hdr-collapse {
    position: absolute;
    padding: 6px;
    right: 0;
    top: 0;
    color: #0085c7;
    opacity: .5
}

.wj-multirow .wj-hdr-collapse:hover {
    opacity: 1
}

.wj-pivotpanel {
    position: relative;
    padding: 3px 6px;
    overflow: auto;
    min-height: 25em;
    width: 100%
}

.wj-pivotpanel table {
    table-layout: fixed;
    width: 100%
}

.wj-pivotpanel div {
    width: 100%
}

.wj-pivotpanel tr, .wj-pivotpanel td {
    border: 1px #e0e0e0;
    padding: 3px
}

.wj-pivotpanel label {
    font-weight: 700;
    margin: 0
}

.wj-pivotpanel .wj-flexgrid {
    border: 0;
    border-radius: 0;
    min-height: 8em;
    max-height: 20em;
    margin-left: 6px;
    background: inherit
}

.wj-pivotpanel table .wj-flexgrid {
    min-height: 5em;
    height: 5em;
    width: 95%
}

.wj-pivotpanel .wj-marker {
    position: absolute;
    background: #0085c7;
    opacity: .5;
    pointer-events: none
}

.wj-pivotpanel .wj-flexgrid .wj-cell {
    border: 0;
    background: inherit;
    color: inherit
}

.wj-pivotpanel .wj-flexgrid .wj-cell.wj-group {
    background: inherit;
    color: inherit
}

.wj-pivotpanel .wj-flexgrid .wj-cell.wj-group:not(.wj-header):hover {
    background: rgba(0, 0, 0, .05);
    border-radius: 4px
}

.wj-pivotpanel .wj-flexgrid .wj-glyph-filter {
    cursor: default;
    opacity: .5
}

.wj-pivotpanel .wj-flexgrid .wj-glyph-dimension, .wj-pivotpanel .wj-flexgrid .wj-glyph-measure {
    cursor: default
}

.wj-pivotpanel .wj-flexgrid .wj-aggregate {
    font-size: 80%;
    opacity: .5
}

.wj-pivotpanel .wj-flexgrid label {
    font-weight: 400;
    margin: 0
}

.wj-pivotfieldeditor {
    min-width: 400px
}

.wj-pivotfieldeditor tr.wj-separator {
    border-top: 10px solid transparent
}

.wj-pivotfieldeditor td:first-child {
    text-align: right;
    padding-right: .5em
}

.wj-pivotfiltereditor {
    padding: 10px;
    min-width: 230px
}

.wj-pivotfiltereditor .wj-control {
    margin-bottom: 6px;
    width: 100%
}

.wj-pivotfiltereditor .wj-listbox {
    max-height: 150px
}

.wj-pivotfiltereditor .wj-listbox .wj-listbox-item label {
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis
}

.wj-olap-context-menu {
    font-size: 90%;
    padding: 6px
}

.wj-olap-context-menu .menu-icon {
    display: inline-block;
    width: 1em;
    margin-right: 6px;
    opacity: .75;
    text-align: center
}

.wj-olap-context-menu .menu-icon.menu-icon-remove {
    color: maroon;
    font-weight: 700
}

.wj-detaildialog .wj-flexgrid {
    width: 60vw;
    height: 40vh
}

.wj-pivotgrid .wj-colheaders .wj-cell.wj-header {
    text-align: center
}

.wj-pivotgrid .wj-topleft .wj-cell.wj-col-field-hdr {
    text-align: right
}

.wj-pivotgrid .wj-cell.wj-aggregate {
    font-weight: 700
}

.wj-pivotgrid .wj-aggregate.wj-cell:not(.wj-header):not(.wj-group):not(.wj-state-selected):not(.wj-state-multi-selected) {
    background: rgba(0, 133, 199, .15)
}

.wj-pivotchart {
    position: relative
}

.wj-pivotchart .wj-dropdown {
    position: absolute;
    margin-right: 15px;
    z-index: 999
}

.wj-pivotchart .wj-flexchart {
    padding: 35px 15px 10px
}

.wj-slicer>div {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
    height: 100%;
    max-height: inherit
}

.wj-slicer .wj-header {
    -ms-flex-negative: 0;
    flex-shrink: 0;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center
}

.wj-slicer .wj-header div:first-child {
    -webkit-box-flex: 1;
    -ms-flex-positive: 1;
    flex-grow: 1;
    padding: 6px
}

.wj-slicer .wj-header .wj-btn.wj-state-active, .wj-slicer .wj-header .wj-viewer .wj-control .wj-state-active.wj-applybutton, .wj-viewer .wj-control .wj-slicer .wj-header .wj-state-active.wj-applybutton {
    background: rgba(0, 0, 0, .2)
}

.wj-slicer .wj-listbox {
    border: 0;
    border-radius: 0
}

.wj-slicer .wj-listbox .wj-listbox-item label {
    width: 100%
}

.wj-slicer.wj-nocheck .wj-listbox .wj-listbox-item {
    border: 2px solid transparent;
    padding: 6px;
    margin: 4px;
    border-radius: 4px
}

.wj-slicer.wj-nocheck .wj-listbox .wj-listbox-item.wj-state-checked:not(:hover) {
    background-color: #0085c7;
    color: #fff
}

.wj-slicer.wj-nocheck .wj-listbox .wj-listbox-item.wj-state-selected {
    border-color: #80adbf
}

.wj-slicer.wj-nocheck .wj-listbox .wj-listbox-item.wj-state-selected:not(.wj-state-checked) {
    background-color: transparent;
    color: #000
}

.wj-slicer.wj-nocheck .wj-listbox .wj-listbox-item input {
    display: none
}

.wj-viewer {
    height: 750px
}

.wj-viewer .wj-viewer-outer {
    width: 100%;
    height: 100%;
    display: block;
    position: relative;
    padding-top: 50px
}

.wj-viewer .wj-viewer-outer input {
    background: #fff;
    color: #000
}

.wj-viewer .wj-viewer-container {
    height: 100%;
    width: 100%
}

.wj-viewer .wj-viewer-leftpanel {
    float: left;
    height: 100%
}

.wj-viewer .wj-viewer-splitter {
    cursor: pointer;
    float: left;
    height: 100%;
    user-select: none;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none
}

.wj-viewer .wj-viewer-splitter button {
    height: 100%;
    padding: 0 2px;
    min-width: 12px;
    background: #fafafa;
    border-left: 1px solid #c8c8c8;
    border-right: 1px solid #c8c8c8;
    border-top: none;
    border-bottom: none
}

.wj-viewer .wj-viewer-splitter button:hover {
    background: #dadada
}

.wj-viewer .wj-viewer-splitter span {
    text-align: center
}

.wj-viewer .wj-viewpanel-container {
    position: relative;
    height: 100%;
    width: 100%;
    overflow: hidden
}

.wj-viewer .wj-viewpanel-container.move .wj-view-page {
    cursor: move;
    user-select: none;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none
}

.wj-viewer .wj-viewpanel-container.rubberband-actived .wj-view-page, .wj-viewer .wj-viewpanel-container.magnifier-actived .wj-view-page {
    cursor: default;
    user-select: none;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none
}

.wj-viewer .wj-viewpanel-container.rubberband-actived .wj-view-page svg, .wj-viewer .wj-viewpanel-container.magnifier-actived .wj-view-page svg {
    pointer-events: none
}

.wj-viewer .wj-viewpanel-container.rubberband-actived .wj-rubberband.show, .wj-viewer .wj-viewpanel-container.magnifier-actived .wj-magnifier.show {
    display: block
}

.wj-viewer .wj-pageview {
    outline: 0;
    position: relative;
    background: #e5e5e5;
    height: 100%;
    width: 100%;
    text-align: center;
    -webkit-overflow-scrolling: touch
}

.wj-viewer .wj-pageview.hidden {
    display: none;
    visibility: hidden
}

.wj-viewer .wj-pageview-continuous {
    overflow: auto
}

.wj-viewer .wj-pageview-single:not(.virtual) .wj-pageview-pagescontainer {
    overflow-y: auto
}

.wj-viewer .wj-pageview-single:not(.virtual) .wj-pageview-vscroller {
    visibility: hidden
}

.wj-viewer .wj-pageview-pagescontainer {
    outline: 0;
    position: relative;
    height: 100%;
    width: 100%;
    display: inline-block;
    overflow-x: auto;
    overflow-y: scroll;
    text-align: center
}

.wj-viewer .wj-pages-wrapper {
    display: inline-block;
    margin: 30px
}

.wj-viewer .wj-view-page {
    width: 1px;
    height: 1px;
    margin: 0 auto 30px auto;
    background: #fff;
    border: 1px solid rgba(0, 0, 0, .3);
    box-shadow: 0 2px 8px rgba(0, 0, 0, .15)
}

.wj-viewer .wj-view-page .wj-loading {
    background: rgba(204, 204, 204, .8);
    width: 100%;
    height: 100%
}

.wj-viewer .wj-view-page .highlight {
    fill: #0085c7;
    fill-opacity: .5
}

.wj-viewer .wj-view-page rect {
    pointer-events: none
}

.wj-viewer .wj-view-page rect[arsvg\:data-action-type], .wj-viewer .wj-view-page a rect {
    pointer-events: inherit
}

.wj-viewer .wj-view-page:last-child {
    margin-bottom: 0
}

.wj-viewer .wj-pageview-vscroller {
    outline: 0;
    display: inline-block;
    position: absolute;
    right: 0;
    top: 0;
    height: 100%;
    width: 18px;
    float: right;
    overflow-x: hidden;
    overflow-y: scroll
}

.wj-viewer .wj-vscroller-wrapper {
    width: 1px
}

.wj-viewer .wj-toolbar {
    height: 50px;
    width: 100%;
    border-bottom: 1px solid #c8c8c8;
    position: absolute;
    margin-top: -50px
}

.wj-viewer .wj-toolbar:hover .wj-toolbar-move.enabled {
    opacity: 1
}

.wj-viewer .wj-toolbar a:hover, .wj-viewer .wj-toolbar a:focus {
    background: #eee;
    outline: 0
}

.wj-viewer .wj-toolbar a:hover.disabled, .wj-viewer .wj-toolbar a:focus.disabled, .wj-viewer .wj-toolbar a:hover.checked, .wj-viewer .wj-toolbar a:focus.checked {
    background: 0 0;
    cursor: default
}

.wj-viewer .wj-toolbar>.wj-control, .wj-viewer .wj-toolbar .wj-tabcontent, .wj-viewer .wj-viewer-leftpanel>.wj-control, .wj-viewer .wj-viewer-leftpanel .wj-tabcontent {
    background: inherit;
    color: inherit
}

.wj-viewer .wj-content:not(.mobile) .wj-toolbar.mobile {
    display: none
}

.wj-viewer .wj-toolbar-move {
    width: 12px;
    height: 100%;
    cursor: pointer;
    visibility: hidden;
    opacity: .2
}

.wj-viewer .wj-toolbar-move.left {
    float: left
}

.wj-viewer .wj-toolbar-move.right {
    float: right
}

.wj-viewer .wj-toolbar-move .wj-glyph-left, .wj-viewer .wj-toolbar-move .wj-glyph-right {
    margin-top: 20px;
    margin-left: 2px
}

.wj-viewer .wj-toolbarcontainer {
    float: left;
    height: 100%;
    overflow: hidden
}

.wj-viewer .wj-toolbarwrapper {
    position: relative;
    border: 0;
    border-radius: 0;
    padding-top: 5px;
    height: 100%
}

.wj-viewer .wj-toolbarwrapper.wj-btn-group>:not(.wj-control) {
    border-left: none;
    padding-left: 0;
    padding-right: 0;
    position: relative;
    float: left
}

.wj-viewer .wj-toolbarwrapper.wj-btn-group>.wj-separator {
    height: 33px;
    width: 10px;
    float: left;
    border-right: 2px solid #000;
    margin: 3px 10px 0 0;
    opacity: .25
}

.wj-viewer .wj-toolbarwrapper .wj-pagenumber {
    margin: 4px 10px;
    height: 30px;
    width: 80px;
    float: left
}

.wj-viewer .wj-toolbarwrapper .wj-pagecount {
    margin: 5px 10px;
    height: 30px;
    line-height: 30px;
    float: left
}

.wj-viewer .wj-toolbarwrapper .wj-input-zoom {
    margin: 4px 10px;
    height: 30px;
    width: 100px;
    float: left
}

.wj-viewer .wj-toolbarwrapper .slash {
    float: left;
    height: 40px;
    width: 10px;
    line-height: 40px
}

.wj-viewer .wj-toolbarwrapper .hidden {
    display: none
}

.wj-viewer .wj-mini-toolbar {
    position: absolute;
    vertical-align: bottom;
    border: 0;
    bottom: 2em;
    padding: 0 24px;
    width: auto;
    background: #303030;
    visibility: hidden;
    opacity: .8
}

.wj-viewer .wj-mini-toolbar a:focus {
    background: 0 0
}

.wj-viewer .wj-mini-toolbar .slash, .wj-viewer .wj-mini-toolbar .wj-pagecount {
    color: #e0e0e0
}

.wj-viewer .wj-mini-toolbar .wj-pagenumber {
    color: #e0e0e0;
    background: #202020
}

.wj-viewer .wj-mini-toolbar .wj-pagenumber input {
    color: inherit
}

.wj-viewer .wj-mini-toolbar .wj-toolbar-move {
    visibility: hidden;
    display: none
}

.wj-viewer .wj-mini-toolbar .wj-btn:hover, .wj-viewer .wj-mini-toolbar .wj-control .wj-applybutton:hover, .wj-viewer .wj-control .wj-mini-toolbar .wj-applybutton:hover {
    background: #606060
}

.wj-viewer .wj-mini-toolbar .wj-btn-group .wj-btn svg.wj-svg-btn, .wj-viewer .wj-mini-toolbar .wj-btn-group .wj-control .wj-applybutton svg.wj-svg-btn, .wj-viewer .wj-control .wj-mini-toolbar .wj-btn-group .wj-applybutton svg.wj-svg-btn {
    fill: #fff;
    opacity: 1
}

.wj-viewer .wj-nav {
    padding-left: 0;
    list-style: none;
    float: left;
    border-bottom: 0;
    margin-bottom: -1px
}

.wj-viewer .wj-nav>li {
    border: 0
}

.wj-viewer .wj-nav>li:not(:first-child) {
    border: 0
}

.wj-viewer .wj-nav>li.hidden {
    display: none
}

.wj-viewer .wj-nav>li>a {
    width: 48px;
    height: 48px
}

.wj-viewer .wj-nav>li>a:hover, .wj-viewer .wj-nav>li>a:focus {
    text-decoration: none;
    background: #eee
}

.wj-viewer .wj-arrow {
    display: none
}

.wj-viewer .wj-arrow .wj-insidearrow {
    position: absolute;
    top: 17px;
    right: -1px;
    height: 0;
    width: 0;
    border: 7px solid transparent;
    border-right-color: #fafafa;
    z-index: 1000
}

.wj-viewer .wj-arrow .wj-outsidearrow {
    position: absolute;
    top: 16px;
    right: -1px;
    height: 0;
    width: 0;
    border: 8px solid transparent;
    border-right-color: #c8c8c8
}

.wj-viewer .wj-viewer-tabsleft {
    height: 100%;
    position: relative
}

.wj-viewer .wj-viewer-tabsleft.collapsed .wj-tabcontent {
    width: 0;
    visibility: collapse
}

.wj-viewer .wj-tabcontent {
    float: right;
    height: 100%;
    width: auto
}

.wj-viewer .wj-tabcontent h3 {
    line-height: 1.1;
    margin-bottom: 10px;
    margin-top: 0;
    font-family: -apple-system-font, 'Segoe UI Light', 'Segoe UI', 'Roboto', sans-serif;
    font-size: 24px;
    color: inherit
}

.wj-viewer .wj-tabcontent legend {
    color: inherit
}

.wj-viewer .wj-tabcontent .active {
    left: 0;
    width: 300px;
    height: 100%;
    border-left: 1px solid #c8c8c8;
    display: block
}

.wj-viewer .wj-tabcontent .wj-tabpane .wj-tabcontent-inner {
    overflow: auto;
    height: 100%;
    border-top: 1px solid #c8c8c8
}

.wj-viewer .wj-tabpane {
    background: #fafafa;
    display: none
}

.wj-viewer .wj-tabtitle-wrapper {
    position: absolute;
    width: 299px;
    padding: 10px
}

.wj-viewer .wj-tabtitle-wrapper .wj-close {
    position: absolute;
    top: .2em;
    right: .3em;
    font-size: 30pt;
    font-weight: 700;
    line-height: .9em;
    opacity: .5;
    cursor: pointer
}

.wj-viewer .wj-tabtitle-wrapper .wj-close:hover {
    opacity: .75
}

.wj-viewer .wj-tabtitle-wrapper .wj-exportcontainer label {
    margin-right: 8px
}

.wj-viewer .wj-tabcontent-wrapper {
    height: 100%;
    padding-top: 48px
}

.wj-viewer .wj-tabcontent-wrapper.search-wrapper {
    padding-top: 202px
}

.wj-viewer .wj-tabcontent-wrapper.wj-exportformats-wrapper {
    padding-top: 120px
}

.wj-viewer .wj-tabcontent-wrapper.wj-exportformats-wrapper .wj-export-editor {
    width: 100%
}

.wj-viewer .wj-tabcontent-wrapper.wj-exportformats-wrapper .wj-export-editor td {
    font-size: 8pt
}

.wj-viewer .wj-tabcontent-wrapper.wj-exportformats-wrapper .wj-export-editor td:first-child {
    width: 200px
}

.wj-viewer .wj-tabcontent-wrapper.wj-exportformats-wrapper .wj-export-editor td:last-child {
    width: 150px
}

.wj-viewer .wj-tabcontent-wrapper.wj-exportformats-wrapper .wj-export-editor .wj-exportformats-group {
    margin: 8px
}

.wj-viewer .wj-tabcontent-wrapper.wj-exportformats-wrapper .wj-exportformats-footer {
    padding: 8px
}

.wj-viewer .wj-outlines-tree {
    display: block;
    width: 100%;
    border: 0;
    border-radius: 0;
    background: #fafafa;
    padding-top: 20px;
    padding-left: 4px
}

.wj-viewer .wj-cell {
    border: 0;
    background: #fafafa;
    color: #000;
    cursor: pointer
}

.wj-viewer .wj-cell span {
    margin-right: 5px
}

.wj-viewer .wj-cell a:link, .wj-viewer .wj-cell a:visited, .wj-viewer .wj-cell a:hover {
    color: #000;
    text-decoration: none
}

.wj-viewer .wj-cell.wj-state-selected {
    background: #eee;
    color: #000
}

.wj-viewer .wj-cell.wj-state-multi-selected {
    background: #fafafa;
    color: #000
}

.wj-viewer .wj-cell.wj-header {
    color: #fff;
    background: #000;
    border: 0;
    font-weight: 700
}

.wj-viewer .wj-cell.wj-alt:not(.wj-state-selected):not(.wj-state-multi-selected) {
    background: #fafafa;
    color: #000
}

.wj-viewer .wj-cell.wj-group:not(.wj-state-selected):not(.wj-state-multi-selected) {
    background: #fafafa;
    color: #000
}

.wj-viewer .wj-thumbnaillist {
    display: block;
    width: 100%;
    border: 0;
    border-radius: 0;
    background: inherit;
    color: inherit
}

.wj-viewer .wj-thumbnail-item {
    height: auto;
    text-align: center;
    padding-top: 35px
}

.wj-viewer .wj-thumbnail-item .wj-pagethumbnail {
    background: #fff;
    border: 1px solid #c8c8c8;
    cursor: pointer;
    outline: 0;
    margin: 0 auto 10px auto
}

.wj-viewer .wj-thumbnail-item.wj-state-selected {
    background: 0 0;
    color: inherit
}

.wj-viewer .wj-thumbnail-item.wj-state-selected .wj-pagethumbnail {
    border-color: #0085c7
}

.wj-viewer .wj-thumbnail-item:not(.wj-state-selected):not(.wj-state-disabled):not(.wj-separator):hover {
    background: 0 0;
    color: inherit
}

.wj-viewer .wj-parameterscontainer {
    float: left;
    width: 100%;
    padding-left: 10px;
    padding-right: 10px
}

.wj-viewer .wj-parameterscontainer.wj-parametererrors-hidden .wj-parametercontainer .error {
    display: none
}

.wj-viewer .wj-parametercontainer {
    float: left;
    margin-top: 20px
}

.wj-viewer .wj-parametercontainer .wj-parametercontrol {
    width: 100%
}

.wj-viewer .wj-parametercontainer .error {
    color: red;
    float: right
}

.wj-viewer .wj-parametercontainer textarea {
    float: right;
    resize: none;
    height: 100px;
    width: 140px
}

.wj-viewer .wj-searchoption {
    margin-top: 10px;
    float: left;
    width: 100%
}

.wj-viewer .wj-searchoption input {
    width: 16px;
    height: 16px;
    float: left;
    margin: 2px 0 0
}

.wj-viewer .wj-searchoption label {
    margin-top: 2px;
    float: left;
    font-weight: initial
}

.wj-viewer .wj-searchresult {
    padding-top: 15px;
    float: left
}

.wj-viewer .wj-searchresultlist {
    float: left;
    width: 100%;
    padding: 10px;
    border: 0;
    border-radius: 0;
    background: 0 0;
    color: #444
}

.wj-viewer .wj-searchresultlist .wj-search-item {
    cursor: pointer
}

.wj-viewer .wj-searchresultlist .wj-search-item .wj-search-text {
    font-size: 125%
}

.wj-viewer .wj-searchresultlist .wj-search-item:not(.wj-state-selected):not(:hover) .wj-search-text {
    color: #1e1e1e
}

.wj-viewer .wj-control .wj-applybutton {
    float: right;
    margin-top: 20px;
    border-radius: 4px;
    padding: 4px 1em
}

.wj-viewer .wj-searchbar {
    height: 50px;
    width: 100%;
    border-top: 1px solid #c8c8c8;
    position: relative;
    margin: 0 auto
}

.wj-viewer .wj-searchbar.hidden {
    visibility: hidden;
    display: none
}

.wj-viewer .wj-searchbox {
    border: 1px solid rgba(0, 0, 0, .2);
    border-radius: 4px;
    padding: 4px 6px 3px 6px;
    width: 100%;
    border-right: 4.75em solid #eee
}

.wj-viewer .mobile .wj-searchbox {
    border-right-width: 1px
}

.wj-viewer .wj-searchcontainer {
    position: relative;
    padding-top: 4px
}

.wj-viewer .wj-searchcontainer .wj-btn-group {
    position: absolute;
    right: .1em
}

.wj-viewer .wj-searchcontainer .wj-btn-group>.wj-btn-search {
    padding-top: 4px
}

.wj-viewer .wj-searchcontainer .wj-btn-group button {
    background: 0 0;
    height: 30px;
    width: 30px;
    border: 0;
    padding: 1px
}

.wj-viewer .wj-toolbarcontainer {
    width: 100%
}

.wj-viewer .wj-toolbarwrapper {
    width: 100%
}

.wj-viewer .wj-toolbarwrapper>.wj-btn, .wj-viewer .wj-control .wj-toolbarwrapper>.wj-applybutton {
    display: inline-block
}

.wj-viewer .wj-toolbarwrapper>.wj-btn.hidden, .wj-viewer .wj-control .wj-toolbarwrapper>.hidden.wj-applybutton {
    display: none
}

.wj-viewer .wj-toolbarwrapper .wj-searchbtn-groups {
    float: right;
    width: auto;
    padding-top: 0
}

.wj-viewer .wj-rubberband {
    position: absolute;
    border: solid;
    border-color: #0085c7;
    border-width: 2px;
    display: none;
    z-index: 1
}

.wj-viewer .wj-magnifier {
    border: solid;
    border-color: #0085c7;
    border-width: 2px;
    width: 242px;
    height: 150px;
    position: absolute;
    display: none;
    overflow: hidden;
    cursor: default;
    user-select: none;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    pointer-events: none;
    z-index: 1
}

.wj-viewer .wj-magnifier .wj-view-page {
    position: absolute
}

.wj-viewer .wj-viewer-footer {
    height: 50px;
    width: 100%;
    border-top: 1px solid #c8c8c8;
    position: relative;
    display: none
}

.wj-viewer .wj-viewer-footer .wj-toolbar {
    margin: 0 auto;
    left: 0;
    right: 0
}

.wj-viewer .wj-viewer-footer .wj-close {
    font-size: 30pt;
    line-height: 20px;
    float: right;
    font-weight: 700;
    color: #000;
    opacity: .2;
    cursor: pointer;
    margin-top: 12px
}

.wj-viewer .wj-btn-group {
    border: 0
}

.wj-viewer .wj-btn-group>.wj-btn, .wj-viewer .wj-control .wj-btn-group>.wj-applybutton {
    padding: 8px;
    margin: 0;
    width: 34px;
    height: 36px;
    background: 0 0
}

.wj-viewer .wj-btn-group>li>a.wj-btn, .wj-viewer .wj-control .wj-btn-group>li>a.wj-applybutton {
    padding: 12px 10px;
    background: 0 0
}

.wj-viewer .wj-btn-group>li>a.wj-btn:focus, .wj-viewer .wj-control .wj-btn-group>li>a.wj-applybutton:focus, .wj-viewer .wj-btn-group>li>a.wj-btn:hover, .wj-viewer .wj-control .wj-btn-group>li>a.wj-applybutton:hover {
    background: #e1e1e1
}

.wj-viewer .wj-viewer-loading {
    background: rgba(204, 204, 204, .8);
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    text-align: center;
    font-size: 20pt
}

.wj-viewer .wj-viewer-loading .verticalalign {
    vertical-align: middle;
    display: inline-block;
    width: 1px;
    height: 100%;
    margin-left: -1px
}

.wj-viewer .wj-viewer-loading .textspan {
    vertical-align: middle;
    display: inline-block;
    padding: 20px
}

.wj-viewer .wj-viewer-loading.errormessage {
    font-size: inherit
}

.wj-viewer .mobile.with-footer, .wj-viewer .mobile.with-searchbar {
    padding-bottom: 50px
}

.wj-viewer .mobile.with-footer.with-searchbar {
    padding-bottom: 100px
}

.wj-viewer .mobile .wj-viewer-leftpanel {
    width: 0
}

.wj-viewer .mobile .wj-viewer-splitter {
    visibility: hidden;
    display: none
}

.wj-viewer .mobile .wj-toolbar {
    visibility: hidden;
    display: none
}

.wj-viewer .mobile .wj-toolbar .wj-pagenumber {
    width: 50px
}

.wj-viewer .mobile .wj-toolbar .wj-pagecount {
    margin: 5px 7px
}

.wj-viewer .mobile .wj-toolbar .wj-toolbar-move {
    visibility: hidden;
    display: none
}

.wj-viewer .mobile .wj-toolbar.mobile {
    display: block
}

.wj-viewer .mobile .wj-toolbar.mobile.hidden {
    display: none
}

.wj-viewer .mobile .wj-nav {
    display: none;
    visibility: hidden
}

.wj-viewer .mobile .wj-viewer-tabsleft {
    width: 100%;
    position: absolute;
    top: 0;
    z-index: 1000
}

.wj-viewer .mobile .wj-viewer-tabsleft.collapsed {
    width: 0
}

.wj-viewer .mobile .wj-tabcontent {
    width: 100%
}

.wj-viewer .mobile .wj-tabcontent .wj-tabpane .wj-pagesetupcontainer {
    width: 100%
}

.wj-viewer .mobile .wj-tabcontent .wj-tabpane .wj-tabtitle-wrapper {
    position: absolute;
    width: 100%;
    padding: 10px 20px 20px 20px
}

.wj-viewer .mobile .wj-tabcontent .active {
    left: 0;
    width: 100%;
    height: 100%;
    border: 1px solid #c8c8c8;
    display: block
}

.wj-viewer .mobile .wj-viewer-footer {
    display: block
}

.wj-viewer .mobile .wj-viewer-footer.hidden {
    display: none
}

.wj-viewer .mobile .wj-btn-group>.wj-btn, .wj-viewer .mobile .wj-control .wj-btn-group>.wj-applybutton, .wj-viewer .wj-control .mobile .wj-btn-group>.wj-applybutton {
    padding-top: 9px
}

.wj-viewer .mobile .mobile {
    visibility: visible
}

.wj-viewer.full-screen {
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    display: block;
    position: fixed;
    z-index: 1200
}

.wj-viewer.full-screen .wj-viewer-outer {
    width: 100%;
    height: 100%;
    display: block;
    position: absolute;
    padding-top: 0
}

.wj-viewer.full-screen .wj-viewer-outer.mobile {
    padding-top: 50px
}

.wj-viewer.full-screen .wj-viewer-outer.mobile .wj-toolbar {
    display: block
}

.wj-viewer.full-screen .wj-viewer-outer.mobile .wj-mini-toolbar {
    visibility: hidden;
    display: none
}

.wj-viewer.full-screen .wj-viewer-outer.mobile .wj-viewer-leftpanel {
    display: block
}

.wj-viewer.full-screen .wj-viewer-outer.mobile .wj-searchbar.hidden {
    display: none
}

.wj-viewer.full-screen .wj-viewer-leftpanel {
    display: none
}

.wj-viewer.full-screen .wj-viewer-splitter {
    display: none
}

.wj-viewer.full-screen .wj-toolbar {
    display: none
}

.wj-viewer.full-screen .wj-mini-toolbar {
    visibility: visible;
    display: inline-block
}

.wj-viewer.narrow .wj-viewer-splitter {
    display: none;
    visibility: hidden
}

.wj-viewer.narrow .wj-viewer-leftpanel {
    display: none;
    visibility: hidden
}

.wj-viewer.wj-control {
    user-select: inherit;
    -webkit-user-select: inherit;
    -moz-user-select: inherit;
    -ms-user-select: inherit
}

.wj-viewer .wj-btn-group .wj-btn svg.wj-svg-btn, .wj-viewer .wj-btn-group .wj-control .wj-applybutton svg.wj-svg-btn, .wj-viewer .wj-control .wj-btn-group .wj-applybutton svg.wj-svg-btn {
    fill: #444;
    stroke: #444
}

.wj-viewer .wj-btn-group .wj-btn.wj-state-active svg.wj-svg-btn, .wj-viewer .wj-btn-group .wj-control .wj-state-active.wj-applybutton svg.wj-svg-btn, .wj-viewer .wj-control .wj-btn-group .wj-state-active.wj-applybutton svg.wj-svg-btn {
    fill: #3986c7;
    opacity: 1;
    stroke: #3986c7
}

.wj-viewer .wj-btn-group .wj-btn:hover svg.wj-svg-btn, .wj-viewer .wj-btn-group .wj-control .wj-applybutton:hover svg.wj-svg-btn, .wj-viewer .wj-control .wj-btn-group .wj-applybutton:hover svg.wj-svg-btn {
    opacity: 1
}

.wj-viewer-menu.wj-btn-group-vertical .wj-listbox-item.wj-state-active .wj-btn .wj-svg-btn, .wj-viewer-menu.wj-btn-group-vertical .wj-listbox-item.wj-state-active .wj-viewer .wj-control .wj-applybutton .wj-svg-btn, .wj-viewer .wj-control .wj-viewer-menu.wj-btn-group-vertical .wj-listbox-item.wj-state-active .wj-applybutton .wj-svg-btn {
    fill: #3986c7;
    opacity: 1
}

.wj-viewer-menu.wj-btn-group-vertical .wj-listbox-item.wj-state-active .wj-btn .wj-svg-btn .wj-hollow-svg, .wj-viewer-menu.wj-btn-group-vertical .wj-listbox-item.wj-state-active .wj-viewer .wj-control .wj-applybutton .wj-svg-btn .wj-hollow-svg, .wj-viewer .wj-control .wj-viewer-menu.wj-btn-group-vertical .wj-listbox-item.wj-state-active .wj-applybutton .wj-svg-btn .wj-hollow-svg {
    fill: none;
    stroke: #3986c7
}

.wj-viewer-menu>.wj-listbox-item {
    white-space: nowrap;
    padding: 0 10px 10px 10px
}

.wj-viewer-menu>.wj-listbox-item a.wj-btn, .wj-viewer-menu>.wj-listbox-item .wj-viewer .wj-control a.wj-applybutton, .wj-viewer .wj-control .wj-viewer-menu>.wj-listbox-item a.wj-applybutton {
    background: 0 0;
    transform: translateY(.4em)
}

.wj-viewer-menu>.wj-listbox-item .checkIcon {
    width: 20px;
    margin-right: 5px;
    visibility: hidden
}

.wj-viewer-menu>.wj-listbox-item.checked .checkIcon {
    visibility: visible
}

.wj-viewer-menu>.wj-listbox-item.hidden {
    display: none;
    visibility: hidden
}

.wj-viewer svg.wj-svg-btn, .wj-viewer-menu svg.wj-svg-btn {
    opacity: .75;
    width: 22px;
    height: 22px;
    stroke-width: 0
}

body.full-screen {
    overflow: hidden
}

@media print {
    .wj-viewer .wj-toolbar-move {
        visibility: hidden;
        display: none
    }

    .wj-viewer .wj-viewpanel-container {
        display: inline-block;
        float: none
    }
}

.wj-flexchart .fibonacci-arcs path.wj-state-selected {
    stroke-dasharray: 5 10;
    stroke-linecap: butt
}

input[wj-part="chk-defer"]{
    display: none;
}

span[wj-part="g-defer"]{
    display: none;
}

button.wj-btn[wj-part="btn-update"]{
    display: none;
}

.wj-pivotpanel {
    background-color: var(--primary) !important;
}

.wj-flexgrid div[wj-part='root'] {
    background: var(--primary) !important;
    color: var(--text-color-primary);
}

.wj-pivotpanel label {
    color: var(--text-color-primary);
}