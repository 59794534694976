button,
html,
input,
select,
textarea {
    font-family: Roboto, Helvetica Neue, sans-serif;
}

body,
html {
    height: 100%;
    position: relative;
}

*,
html {
    margin: 0;
    padding: 0;
}

* {
    font-weight: 500;
}

*:focus {
    outline: none;
}

html {
    height: 100%;
}

body {
    min-width: 400px;
    width: auto;
    background-color: var(--primary);
    color: var(--text-color-primary);
    margin: 0;
    padding: 0;
    z-index: 0;
    transition: background-color var(--bg-transition), color var(--bg-transition);
}

li.a {
    list-style: circle !important;
}

form {
    padding: 25px 25px;
    display: flex;
    flex-direction: column;
    background: transparent;
    box-shadow: var(--base-shadow);
    border-radius: 10px;
    background-color: var(--primary);
}
form>h2 {
    margin-bottom: 2rem;
}

form input.default[type='number'] {
    height: auto;
    padding: 0;
    width: auto;
}
/* input[type="number"] {
    padding-top: 17px;
} */

input[type='text'],
[type='password'] {
    cursor: text;
    user-select: text;
}

select {
    margin: 0;
    order: 2;
    display: flex;
}

li {
    list-style: none;
}

a {
    cursor: pointer;
}

span {
    display: inline-block;
}

button {
    text-transform: uppercase;
}

iframe {
    width: 100%;
    height: 100%;
    border: none;
}

iframe[title~='recaptcha'] {
    height: 76px;
    width: 99%;
    border: 0px;
}

.primary-text {
    color: var(--text-color-primary) !important;
}

.secondary-text {
    color: var(--text-color-secondary) !important;
}

.color-lime {
    color: rgb(174, 255, 0) !important;
}

.color-yellow {
    color: yellow !important;
}

.color-grey {
    color: #757575;
}

.color-green {
    color: #3cba54 !important;
}

.color-orange {
    color: orange;
}

.color-red {
    color: red !important;
}