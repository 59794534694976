@tailwind base;
@tailwind components;
@tailwind utilities;

/* titlebar.html */
#titlebar-selector .mat-mdc-select-arrow {
    color: white !important;
}

/* user-settings.html */
#user_settings_container .mat-mdc-form-field {
    margin-bottom: 1.25rem;
}
#user_settings_container .mat-mdc-form-field:first-of-type {
    margin-top: 0.5rem;
}

/* widget-spinner */
#widget-spinner .mat-mdc-progress-spinner circle,
#widget-spinner .mat-mdc-progress-spinner circle {
    stroke: var(--dashboard-spinner-color) !important;
}

/* eoi-detail */
#eoi-circle {
    clip: rect(0px, 20px, 40px, 0px);
}
#eoi-bar {
    clip: rect(0px, 40px, 40px, 20px);
}
#reports__jobs-table .mat-mdc-button {
    color: inherit;
}
#eoi_table tr:nth-child(even) {
    background-color: var(--table-odd-row-bg) !important;
    border-top: 1px solid black !important;
    border-bottom: 1px solid black !important;
}
#eoi_container .mat-sort-header-container {
    background-color: var(--table-header-bg) !important;
}

/* manage-user-privilege */
#managePrivilege span {
    width: 100%
}

/* report-wizard-stores */
#itemList {
    display: flex;
    overflow-wrap: break-word;
    justify-content: space-around;

    flex-wrap: wrap;
    height: 100%;
    max-width: 100%;
}

#rightColumnList,
#leftColumnList {
    margin: 0.1rem;
    width: 45%;
    max-width: 100%;
}

@media screen and (max-width: 1100px) {
    #itemList {
        flex-direction: column;
        justify-content: start;
        align-items: center;
        flex-wrap: nowrap;
    }

    #leftColumnList {
        width: 90%;
        height: 100%;
    }

    #rightColumnList {
        display: none;
    }
}

/* schedule-jobs.tpl */
#tablebody tr:nth-child(even) {
    background-color: var(--table-odd-row-bg);
}

/* store portal */

#storePortalBody .mat-mdc-tab-header {
    display: none;
}

/* price change time picker */
.timepicker-overlay {
    z-index: 10000000 !important;
}

.timepicker-backdrop-overlay {
    z-index: 10000000 !important;
}

.timepicker {

    .timepicker__header {
      background-color: var(--secondary) !important;
    }

    .clock-face__number {
        > span.active {
          background-color: var(--secondary) !important;
        }
      }

      .clock-face__clock-hand {
        background-color: var(--secondary) !important;
    
        &::after {
          background-color: var(--secondary) !important;
        }
    
        &::before {
          border-color: var(--secondary) !important;
        }
      }

      .timepicker__actions .timepicker-button {
        color: var(--secondary);
    }
   
}

.full-screen-overlay {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 999;
    color: white;
    font-size: 20px;
}

body.overlay-active {
    overflow: hidden;
}

.progress-container {
    width: 100%;
    height: 10px;
    background: #ddd;
    border-radius: 5px;
    overflow: hidden;
    margin: 10px 0;
}

.progress-bar {
    height: 100%;
    background: #007bff; /* Blue progress bar */
    transition: width 0.3s ease-in-out;
}