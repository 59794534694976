.mat-mdc-list-item-unscoped-content {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.mat-mdc-list-item {
    border-bottom: solid 2px;
    border-color: var(--table-odd-row-bg);
    transition: border-color var(--bg-transition);
    margin-top: 0px;
    margin-bottom: 0px;
}

.mat-mdc-list-item .mdc-list-item__content {
    min-height: 3em;
    display: flex;
    align-items: center;
    cursor: pointer;
}

.mat-mdc-list-item .mdc-list-item__content span div div {
    display: flex;
    align-items: center;
}

mat-divider {
    width: 100% !important;
}

.options-bottom-sheet .mdc-list-group__subheader {
    margin: 0;
}

.options-bottom-sheet .mdc-list {
    padding: 0 !important;
}

.mat-icon {
    overflow: visible !important;
}

.mdc-tab {
    flex-grow: 0 !important;
}

.mat-mdc-focus-indicator {
    color: var(--icon-default-primary) !important;
}

.mat-mdc-focus-indicator .mdc-tab__text-label {
    color: var(--text-color-secondary) !important;
    text-transform: uppercase;
    font-size: 1em;
}

.mat-mdc-focus-indicator mat-icon {
    color: var(--text-color-secondary);
    font-size: 1.75em;
}

.mdc-tab-indicator--active .mdc-tab__text-label {
    color: var(--text-color-tertiary);
}

.mdc-tab-indicator__content--underline {
    --mdc-tab-indicator-active-indicator-color: color: var(--text-color-tertiary) !important;
}

.mdc-tab-indicator--active mat-icon {
    color: var(--text-color-tertiary);
}

.mdc-tooltip {
    background-color: transparent;
}

.mdc-tooltip__surface {
    color: white !important;
    background-color: grey !important;
}

.mat-mdc-tooltip-show {
    background-color: transparent !important;
}

.mat-mdc-slide-toggle .mdc-switch:enabled .mdc-switch__track::after {
    background-color: var(--toggle-light) !important;
}

.mdc-switch__icons .mdc-switch__icon--on {
    background-color: var(--toggle) !important;
    border-radius: 20px;
}

.mat-mdc-slide-toggle .mdc-switch.mdc-switch--selected .mdc-switch__icon {
    width: auto !important;
    height: auto !important;
}

.bg-warn {
    background-color: #f44336 !important;
}

.bg-accent {
    background-color: #ff4081 !important;
}

/* .bg-primary {
    background-color: #3f51b5 !important;
} */

.titlebar .mat-mdc-form-field .mat-mdc-text-field-wrapper {
    background-color: var(--secondary) !important;
}

.titlebar .mat-mdc-form-field-infix mat-label {
    color: var(--secondary) !important;
}

.titlebar .mat-mdc-select-arrow {
    color: white;
}

.mat-bottom-sheet-container .mat-list-item {
    background-color: var(--primary) !important;
}

.mdc-list-item__primary-text,
.mat-mdc-select-min-line {
    color: var(--text-color-primary) !important;
}

.mat-primary .mat-pseudo-checkbox-checked.mat-pseudo-checkbox-minimal::after {
    color: var(--icon-default-primary) !important;
}

.mat-mdc-select-arrow {
    color: var(--icon-default-tertiary) !important;
}

.mdc-form-field label {
    color: var(--text-color-primary) !important;
}

.mat-mdc-dialog-content,
.mat-mdc-dialog-actions,
.mat-mdc-dialog-surface {
    color: var(--text-color-primary) !important;
    background-color: var(--primary) !important;
    border-radius: 1rem !important;
}

.mat-mdc-tab:not(.mat-mdc-tab-disabled).mdc-tab--active .mdc-tab__text-label,
.mat-mdc-tab-link:not(.mat-mdc-tab-disabled).mdc-tab--active .mdc-tab__text-label {
    color: var(--text-color-tertiary) !important;
}

.mat-mdc-dialog-content form {
    margin-bottom: -50px;
}

.mat-focused ::placeholder {
    /* Chrome, Firefox, Opera, Safari 10.1+ */
    color: var(--text-color-primary) !important;
    opacity: 1;
    /* Firefox */
}

/* 
.titlebar .cdk-overlay-pane {
    min-width: 15em !important;
} */

/* .small-form-container button:disabled {
    background-color: gray !important;
    border-color: gray !important;
} */


.viewReportsForm .mat-mdc-text-field-wrapper {
    height: 40px !important;
}

.viewReportsForm .mat-mdc-form-field-subscript-wrapper {
    height: 10px !important;
}

.storePortalAlias .mat-mdc-form-field-subscript-wrapper {
    height: 0px !important;
}

.storePortalAlias .mat-mdc-text-field-wrapper {
    height: 50px !important;
}

.mat-mdc-dialog-container .mat-mdc-dialog-surface,
.mdc-dialog__container,
mat-dialog-container {
    min-width: 300px !important;
}

.mat-dialog-content {
    width: 100% !important;
}

.material-tb mat-icon {
    margin: 0;
}

.all-users-header div {
    display: flex;
    align-items: center;
    height: 70px;
    flex-wrap: nowrap;
}

/* not being used anywhere */
/* .user-privileges-list span {
    width: 100%;
} */

.help_a {
    color: var(--text-color-primary) !important;
}

.help__list-button {
    color: var(--text-color-secondary) !important;
}

.mat-mdc-tab-nav-bar,
.mat-mdc-tab-header {
    border-bottom: 1px solid rgba(0, 0, 0, .12);
}

.mat-expansion-panel {
    background: var(--primary) !important;
    color: var(--text-color-primary) !important;
}

.mat-expansion-panel-header-title {
    color: var(--text-color-primary) !important;
}

.mat-expansion-panel-header-description,
.mat-expansion-indicator::after {
    color: var(--text-color-secondary) !important;
}

.mat-button-toggle-standalone.mat-button-toggle-appearance-standard,
.mat-button-toggle-group-appearance-standard {
    border: solid 1px var(--border-color-secondary);
}

.mat-button-toggle-appearance-standard {
    background: var(--primary) !important;
    color: var(--text-color-primary) !important;
}

.mat-button-toggle-checked {
    background: var(--tertiary) !important;
    color: var(--text-color-primary) !important;
}

.mat-button-toggle-group-appearance-standard .mat-button-toggle+.mat-button-toggle {
    border-left: solid 1px var(--primary);
}

.mat-button-toggle-button {
    text-transform: none !important;
}

.mat-step-icon-content mat-icon {
    color: white !important;
}

.mat-horizontal-content-container {
    background-color: var(--primary);
}

.mat-horizontal-stepper-header-container {
    background-color: var(--primary);
}

mat-step-header .mat-step-label {
    color: var(--text-color-primary) !important;
}

.mat-stepper-horizontal-line {
    border-color: var(--text-color-tertiary) !important;
}

.mdc-tab__text-label {
    font-size: 14px !important;
    letter-spacing: 0em !important;
}

.mdc-list-item__primary-text {
    font-size: 14px !important;
    letter-spacing: 0em !important;
}

.mdc-button {
    letter-spacing: 0em !important;
}

.mat-calendar-body-disabled .mat-focus-indicator {
    color: var(--text-color-tertiary) !important;
}

.mat-calendar-controls .mdc-button__label span {
    color: var(--text-color-primary) !important;
}

.mat-calendar-controls span.mdc-button__label {
    color: var(--text-color-primary) !important;
}

 .mat-calendar-body-cell-content.mat-focus-indicator{
    color: var(--text-color-primary);
  }

.mat-calendar-table-header th{
    color: var(--text-color-primary)  !important;
}

.mat-calendar-arrow{
    fill: var(--text-color-primary)  !important;
}
.mat-calendar-body-label{
    color: var(--text-color-primary)  !important;
}
.mat-calendar-previous-button.mdc-icon-button.mat-mdc-icon-button.mat-unthemed.mat-mdc-button-base{
    color: var(--text-color-primary)  !important;
}

.mat-calendar-next-button.mdc-icon-button.mat-mdc-icon-button.mat-unthemed.mat-mdc-button-base{
    color: var(--text-color-primary)  !important;
}

.mdc-menu-surface {
    background-color: var(--primary) !important;
}

.mat-mdc-form-field-focus-overlay {
    background-color: transparent !important;
}

.mdc-text-field--filled:not(.mdc-text-field--disabled) {
    background-color: transparent !important;
}

.mdc-text-field--disabled.mdc-text-field--filled {
    background-color: transparent !important;
}

.mdc-text-field--filled:not(.mdc-text-field--disabled) .mdc-line-ripple::before {
    border-bottom-color: var(--text-color-secondary) !important;
}

.mdc-text-field--invalid:not(.mdc-text-field--disabled) .mdc-line-ripple::before {
    border-bottom-color: var(--mdc-theme-error, #f44336) !important;
}

.mat-filter {
    background-color: var(--primary) !important;
}


.mat-mdc-form-field-hint {
    color: var(--text-color-secondary) !important;
}


.mat-mdc-input-element {
    color: var(--text-color-primary) !important;
}


.mat-mdc-select-panel {
    background-color: var(--primary);
}


.mat-datepicker-content,
.mat-datepicker-toggle,
.mat-datepicker-content .mat-calendar-next-button,
.mat-datepicker-content .mat-calendar-previous-button,
.mat-calendar-table-header,
.mat-calendar-body-label,
.mat-calendar-body-cell-content,
.mat-date-range-input-separator {
    color: var(--text-color-primary);
}

.mat-datepicker-actions {
    background-color: var(--primary);
}


.mat-calendar-body-selected {
    background-color: var(--secondary);
}

.mat-mdc-paginator {
    background-color: var(--tertiary);
    color: var(--text-color-primary);    
}

.mat-mdc-paginator-icon {
    fill: var(--text-color-primary)!important;
}

mat-sidenav-container {
    height: 100%;
}

mat-sidenav {
    background-color: lightcoral;
}

mat-drawer-content {
    overflow: hidden;
}

.mat-drawer {
    position: fixed;
}

gridster {
    background: var(--tertiary) !important;
}

gridster-item {
    background: var(--primary) !important;
}

.gridster-item .wj-flexchart {
    border: none;
    margin: 0;
    padding: 0;
    height: 100% !important;
    width: 100% !important;
    background-color: transparent;
}

.wj-flexchart {
    height: 400px !important;
    background-color: white;
    padding: 8px;
    margin-bottom: 12px;
    display: block;
}

.report__wijmo-panel.wj-control tr:last-child td:last-child {
    /*in view report - sets the 'values' label to red*/
    color: red;
}

.gridster-item-resizable-handler.handle-se {
    border-width: 0 0 40px 40px !important;
}

.example-tree-invisible {
    display: none;
  }
  
  .example-tree ul,
  .example-tree li {
    margin-top: 0;
    margin-bottom: 0;
    list-style-type: none;
  }
  
  /*
   * This padding sets alignment of the nested nodes.
   */
  .example-tree .mat-nested-tree-node div[role=group] {
    padding-left: 40px;
  }
  
  /*
   * Padding for leaf nodes.
   * Leaf nodes need to have padding so as to align with other non-leaf nodes
   * under the same parent.
   */
  .example-tree div[role=group] > .mat-tree-node {
    padding-left: 40px;
  }


.mdc-checkbox__background {
    border-color: var(--text-color-primary) !important; /* Change the color */
  }

  .mat-mdc-icon-button svg{
    color: var(--text-color-primary) !important; /* Change the color */
  }

  .mat-mdc-menu-panel{
    background: var(--primary) !important;
  }
