.loading-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: 100%;
    background: rgba(255, 255, 255, 0.9);
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 1000;
  }
  
  .graph-container {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: flex-start;
    width: 500px; /* Set the width of the graph */
    height: 300px; /* Set the height of the graph */
    position: relative;
    border: 1px solid #ccc; /* Border for the graph */
    padding: 10px;
    background-color: #f9f9f9;
  }
  
  .bars {
    display: flex;
    align-items: flex-end; /* Align bars to the bottom */
    height: 100%;
    width: 100%;
    padding-bottom: 20px; /* Space for x-axis labels */
  }
  
  .bar {
    width: 30px; /* Width of each bar */
    margin-right: 7px; /* Space between bars */
    margin-left: 7px; /* Space between bars */
    position: relative;
    transform-origin: bottom; /* Set the transform origin to bottom */
    animation: fadeIn 0.5s ease-in-out forwards, bounce 1s infinite alternate ease-in-out; /* Use forwards to keep final state */
    opacity: 0; /* Initial opacity for fade-in effect */
  }
  
  /* Fade-in animation */
  @keyframes fadeIn {
    0% {
      transform: scaleY(0); /* Start from no height */
      opacity: 0; /* Start fully transparent */
    }
    100% {
      transform: scaleY(1); /* Scale to full height */
      opacity: 1; /* Fully visible */
    }
  }
  
  /* Bounce animation */
  @keyframes bounce {
    0% {
      transform: translateY(0);
    }
    100% {
      transform: translateY(-15%);
    }
  }
  
  .x-axis {
    position: relative;
    bottom: 0px; /* Position the x-axis at the bottom of the graph */
    width: 100%; /* Full width */
    display: flex;
    justify-content: space-between; /* Space labels evenly */
  }
  
  .axis-label {
    font-size: 14px;
    color: #333;
  }
  
  .start {
    margin-left: 0; /* Align with the left edge */
  }
  
  .middle {
    margin-left: calc(50% - 20px); /* Center the 50% label */
  }
  
  .end {
    margin-right: 0; /* Align with the right edge */
    margin-left: auto; /* Push the label to the right */
  }
  
  .loading-text {
    font-size: 18px;
    color: #333;
    margin-top: 20px;
  }
  