/* not being used anymore */
/* @media screen and (max-width: 1000px) {
    .large-form-container {
        margin: 0;
        border-radius: 0;
        width: auto;
    }
} */

@media screen and (min-width: 960px) {
    .extractDataForm {
        width: 600px;
        margin: 4em auto;
        background-color: var(--primary);
        transition: 300ms;
    }

    .table__title {
        margin-left: 30px;
    }

    .report-button {
        padding-right: 20px;
    }

    .reports__template-selected-container {
        width: 90%;
    }

    .reports__iframe-container {
        width: 90%;
        margin-left: 10%;
    }

    .lock-container {
        justify-content: end;
    }

    .all-items-button {
        margin: 12px 0 50px;
    }

    .table__container_100 {
        width: 80%;
    }
}

@media screen and (max-width: 960px) {
    .users__table {
        min-width: 900px;
    }

    .feedback-icon {
        top: 15px;
        right: 30px;
        font-size: 2.5em;
    }

    .legend-container {
        position: fixed;
        top: auto;
        bottom: 20px;
        margin-left: 0;
    }

    .deleteAllReportsBtn {
        font-size: smaller !important;
    }

    .lock-container {
        justify-content: center;
    }

    .report-stores-header {
        padding: 1em 0 2em;
    }

    /* not being used anymore */
    /* .report-stores-container {
        max-width: none;
        margin: 0;
    } */

    .report-wizard-buttons {
        padding: 0.75em 0 0.75em 0 !important;
        display: flex;
        justify-content: space-between;
    }

    .fw-fnt {
        font-size: 0.9em;
    }

    .fw-title {
        font-size: 0.8em;
    }

    .portal-widget {
        margin: auto;
    }
}

@media screen and (max-width: 900px) {
    .infolist-container {
        display: block;
    }

    .infolist-btns {
        flex-direction: row !important;
        place-content: center center;
        margin: 25px 0 -40px 0;
    }

    .xlarge-form-container {
        margin: 0;
        border-radius: 0;
        padding: 15px;
        width: auto;
        max-width: 100vw;
        height: calc(100vh - 50px);
    }
}

@media screen and (max-width: 775px) {
    .second-crumb {
        display: none !important;
    }

    .breadcrumb__arrow-left {
        display: flex !important;
    }

    .breadcrumb__arrow-right {
        display: none !important;
    }
}

@media screen and (max-width: 750px) {
    .inv-sidenav-button {
        width: 15px;
        margin-top: 130px;
    }

    .locations-toggle-btn {
        right: 1.5em !important;
        top: 0px !important;
        font-size: 14px !important;
        height: 100px !important;
    }

    .inv-search-field {
        margin-left: 15px;
    }
}

@media screen and (max-width: 711px) {
    .dashboard__tabs {
        width: 100%;
        margin: 0;
    }
}

@media screen and (min-width: 650px) {
    .portal-swiper {
        padding: 0 1vw !important;
    }
}

@media screen and (max-width: 650px) {
    .swiper-pagination-bullet {
        margin-top: 8px !important;
        margin-bottom: 8px !important;
    }

    .portal-header {
        height: 40px !important;
    }

    .portal-container {
        height: calc(100vh - 40px) !important;
    }

    .portal-widget {
        margin: 8px 25px 0 5px !important;
        max-width: none !important;
        min-height: calc(100vh - 250px) !important;
        height: auto !important;
    }

    .portal-widget_header {
        min-height: 2em;
    }

    .portal-footer {
        padding: 8px !important;
        display: flex !important;
        justify-content: space-around !important;
        font-size: 0.8em;
    }

    .portal-footer span {
        margin-right: 8px;
    }

    .centered-container {
        height: auto;
        width: auto;
        min-width: 0;
        min-height: 0;
    }

    .home-container {
        margin-top: 65px;
    }

    .wizard-stepper {
        margin-bottom: 0;
    }

    .all-items-button {
        width: 5em;
    }

    .inv-search-field {
        margin: 15px 0 15px 25px;
    }

    .inv-sidenav-button {
        margin-top: 125px;
    }

    .titlebar-selector,
    .titlebar-selector-alt {
        min-width: 80px;
    }

    .user-form-container,
    /* .smaller-form-container, */
    /* .small-form-container, */
    /* .medium-form-container, */
    .extractDataForm {
        margin: 0;
        border-radius: 0;
        width: auto;
    }
}

@media screen and (max-width: 620px) {
    .table__container__eoi {
        border-top-right-radius: 0px;
        border-top-left-radius: 0px;
        margin-top: 50px;
        margin-bottom: 0px;
    }

    .eoi-mat-option-width {
        width:max-content;
    }

    .eoi-mat-option-width mat-option{
        padding-left: 1.5vw;
    }
}

@media screen and (max-width: 500px) {
    .titlebar-selector {
        font-size: 14px;
        max-width: 35vw;
        margin: 0;
    }

    .reorder-item {
        font-size: 0.8em;
    }

    .reorder-item .mat-icon {
        padding: 8px;
    }
}

@media screen and (max-width: 400px) {
    .figure > img {
        width: 320px;
    }

    .ul-container {
        margin-left: auto;
        margin-right: auto;
    }
    /* TODO(mdc-migration): The following rule targets internal classes of input that may no longer apply for the MDC version. */
    mat-input-container {
        margin-bottom: 0;
    }
    /* TODO(mdc-migration): The following rule targets internal classes of input that may no longer apply for the MDC version. */
    .mat-input-container {
        display: inline-block;
        position: relative;
        padding: 2px;
        margin: 18px 0;
        vertical-align: middle;
        -webkit-box-flex: 1;
        box-sizing: border-box;
    }

    body {
        min-width: 320px;
    }

    .titlebar-buttons {
        margin-right: 10px;
    }

    .circle-mask {
        height: 40px;
        width: 40px;
        background-size: 40px;
    }

    .circle {
        height: 40px;
        width: 40px;
    }

    .centered-container ul#login-list {
        justify-content: center;
    }
}

@media screen and (max-width: 320px) {
    .ul-container {
        width: 100%;
    }
}
