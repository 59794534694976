.help__group-items {
  font-weight: 300;
  max-height: 500px;
  opacity: 1;
  overflow: hidden;
}

.help__group-items.ng-hide {
  max-height: 0;
  opacity: 0;
  height: auto;
}

.help__group-items.ng-hide-add,
.help__group-items.ng-hide-remove {
  transition: 0.25s ease-in-out;
  display: block !important;
}

.filter__group-items {
  max-height: 500px;
  opacity: 1;
  overflow: hidden;
}

.filter__group-items.ng-hide {
  max-height: 0;
  opacity: 0;
  height: auto;
}

.filter__group-items.ng-hide-add,
.filter__group-items.ng-hide-remove {
  transition: all 0.25s ease-in-out;
  display: block !important;
}

#wizard.ng-enter,
#wizard.ng-leave {
  position: absolute;
  left: 0;
  right: 0;
  -webkit-transition: all .4s ease-in-out;
  -moz-transition: all .4s ease-in-out;
  -o-transition: all .4s ease-in-out;
  transition: all .4s ease-in-out;
}

#wizard.ng-enter {
  opacity: 0;
  -webkit-transform: scale3d(0.5, 0.5, 0.5);
  -moz-transform: scale3d(0.5, 0.5, 0.5);
  transform: scale3d(0.5, 0.5, 0.5);
}

#wizard.ng-enter.ng-enter-active {
  opacity: 1;
  -webkit-transform: scale3d(1, 1, 1);
  -moz-transform: scale3d(1, 1, 1);
  transform: scale3d(1, 1, 1);
}

#wizard.ng-leave {
  opacity: 1;
  -webkit-transform: translate3d(0, 0, 0);
  -moz-transform: translate3d(0, 0, 0);
  transform: translate3d(0, 0, 0);
}

#wizard.ng-leave.ng-leave-active {
  opacity: 0;
  -webkit-transform: translate3d(-100px, 0, 0);
  -moz-transform: translate3d(-100px, 0, 0);
  transform: translate3d(-100px, 0, 0);
}