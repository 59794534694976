@import './animations.css';
@import './material/material.css';
@import './material/material-override.css';
@import "~@angular/material/prebuilt-themes/indigo-pink.css";
@import './general.css';
@import './app-responsive.css';
@import './intlTelInput.css';
@import './wijmo/wijmo-core.css';
@import './wijmo/wijmo.css';
@import './wijmo/wijmo.min.css';
@import './wijmo/wijmo.theme.material.min.css';
@import './swiper-bundle.css';
@import './loading-screen.css';
/* Core Grid CSS */
@import 'ag-grid-community/styles/ag-grid.css';
/* Quartz Theme Specific CSS */
@import 'ag-grid-community/styles/ag-theme-quartz.css';

/* Theme configuration */
/* We do this here instead of in Tailwind file as it allow to store shadow / transition properties as variables and it's easier to manage */

/* Style that apply to both theme */
:root {
    /* Color */
    --success-color: #16a34a;
    --success-bg: #bbf7d0;
    --error-color: #dc2626;
    --error-bg: #fecaca;
    --warning-color: #d97706;
    --warning-bg: #fef3c7;
    --info-color: #0d9488;
    --info-bg: #99f6e4;
    --loading-color: #475569;
    --loading-bg: #e2e8f0;

    /* Icon */
    /* delete icon */
    --icon-delete-primary: #f44336;
    --icon-delete-secondary: white;
    /* view icon */
    /* edit icon */
    /* add icon */
    /* download icon */
    /* reload icon */
}

/* Light theme */
:root [data-theme='light'] {
    --primary: #fdfdfd;
    --secondary: rgb(14, 78, 121);
    --tertiary: rgb(235, 243, 253);
    --forth: #4b5563;
    --fifth: rgba(10, 52, 80, 1);
    --sixth: #e6e6e6;

    --text-color-primary: black;
    --text-color-secondary: #7f7f7f;
    --text-color-tertiary: rgba(10, 52, 80, 0.8);
    --text-color-forth: #fdfdfd;

    --btn-primary: rgb(14, 78, 121);
    --btn-secondary: rgb(33, 33, 33);
    --btn-tertiary: rgb(41, 92, 180);

    --icon-default-primary: rgb(14, 78, 121);
    --icon-default-secondary: white;
    --icon-default-tertiary: #202020;

    --border-color-primary: rgba(41, 92, 180, 0.479);
    --border-color-secondary: #ececec;

    --table-bg: #fdfdfd;
    --table-color: rgb(33, 33, 33);
    --table-border: rgba(111, 119, 133, 0.479);
    --table-header-color: white;
    --table-header-bg: rgba(10, 52, 80, 1);
    --table-odd-row-bg: rgba(209, 213, 219, 0.5);
    --table-odd-row-color: rgb(33, 33, 33);
    --table-active-row-bg: #14b8a6;
    --table-active-row-color: rgb(33, 33, 33);

    --toggle: rgba(255, 64, 129, 0.87);
    --toggle-light: rgba(255, 110, 158, 0.87);

    --dashboard-spinner-color: #cccccc;

    --inverted: invert(0);
    --bg-transition: 0.25s ease;

    --base-shadow: rgba(14, 30, 37, 0.12) 0px 2px 4px 0px, rgba(14, 30, 37, 0.32) 0px 2px 16px 0px;
    --hover-shadow: #2f34521f 0px 0px 2px, #2f34521f 0px 0px 4px, #2f34521f 0px 0px 8px, #2f34521f 0px 0px 16px,
        #2f34521f 0px 0px 32px, #2f34521f 0px 0px 48px;
    --button-shadow: 0px 3px 1px -2px rgb(0 0 0 / 20%), 0px 2px 2px 0px rgb(0 0 0 / 14%),
        0px 1px 5px 0px rgb(0 0 0 / 12%);
}

/* :root [data-theme='light'] {
    --primary: #f9f9f9;
    --secondary: #2f3452;
    --tertiary: #e7ebff;
    --forth: rgb(75, 77, 99);
    --fifth: #ff5719;
    --sixth: #e6e6e6;

    --text-color-primary: black;
    --text-color-secondary: #7f7f7f;
    --text-color-tertiary: #ff5619a4;
    --text-color-forth: #f9f9f9;

    --btn-primary: #6864d1;
    --btn-secondary: rgb(33, 33, 33);
    --btn-tertiary: rgb(62, 41, 180);

    --icon-default-primary: #2f3452;
    --icon-default-secondary: white;
    --icon-default-tertiary: #202020;

    --border-color-primary: #2f345283;
    --border-color-secondary: #ececec;

    --table-bg: #fdfdfd;
    --table-color: rgb(33, 33, 33);
    --table-border: #2f3452;
    --table-header-color: white;
    --table-header-bg: #2f3452;
    --table-odd-row-bg: rgba(255, 86, 25, 0.192);
    --table-odd-row-color: rgb(33, 33, 33);
    --table-active-row-bg: #14b8a6;
    --table-active-row-color: rgb(33, 33, 33);

    --toggle: #ff5719;
    --toggle-light: #f8931c;

    --dashboard-spinner-color: #cccccc;

    --inverted: invert(0);
    --bg-transition: 0.25s ease;

    --base-shadow: rgba(18, 18, 58, 0.12) 0px 2px 4px 0px, rgba(18, 18, 58, 0.12) 0px 2px 16px 0px;
    --hover-shadow: #341a8a1f 0px 0px 2px, #341a8a1f 0px 0px 4px, #341a8a1f 0px 0px 8px, #341a8a1f 0px 0px 16px,
        #341a8a1f 0px 0px 32px, #341a8a1f 0px 0px 48px;
    --button-shadow: 0px 3px 1px -2px rgb(0 0 0 / 20%), 0px 2px 2px 0px rgb(0 0 0 / 14%),
        0px 1px 5px 0px rgb(0 0 0 / 12%);
} */


/* :root [data-theme='light'] {
    --primary: #f9f9f9;
    --secondary: #DA2C38;
    --tertiary: #F4F0BB;
    --forth: #87C38F;
    --fifth: #226F54;
    --sixth: #e6e6e6;

    --text-color-primary: black;
    --text-color-secondary: #7f7f7f;
    --text-color-tertiary: #da2c38b4;
    --text-color-forth: #f9f9f9;

    --btn-primary: #226F54;
    --btn-secondary: #43291F;
    --btn-tertiary: #87C38F;

    --icon-default-primary: #226F54;
    --icon-default-secondary: white;
    --icon-default-tertiary: #202020;

    --border-color-primary: rgba(41, 180, 83, 0.479);
    --border-color-secondary: #ececec;

    --table-bg: #f9f9f9;
    --table-color: #43291F;
    --table-border: #2f5244;
    --table-header-color: white;
    --table-header-bg: #DA2C38;
    --table-odd-row-bg: #226f5428;
    --table-odd-row-color: rgb(33, 33, 33);
    --table-active-row-bg: #F4F0BB;
    --table-active-row-color: rgb(33, 33, 33);

    --toggle: #f5323fb7;
    --toggle-light: #da2c38b7;

    --dashboard-spinner-color: #cccccc;

    --inverted: invert(0);
    --bg-transition: 0.25s ease;

    --base-shadow: #bd172256 0px 2px 4px 0px, #bd172256 0px 2px 16px 0px;
    --hover-shadow: #226f541c 0px 0px 2px, #226f541c 0px 0px 4px, #226f541c 0px 0px 8px, #226f541c 0px 0px 16px,
        #226f541c 0px 0px 32px, #226f541c 0px 0px 48px;
    --button-shadow: 0px 3px 1px -2px rgb(0 0 0 / 20%), 0px 2px 2px 0px rgb(0 0 0 / 14%),
        0px 1px 5px 0px rgb(0 0 0 / 12%);
} */

/* Dark theme */
:root [data-theme='dark'] {
    --primary: #292929;
    --secondary: rgba(222, 40, 92);
    --tertiary: rgb(66, 37, 37);
    --forth: #532d2e;
    --fifth: #383838;
    --sixth: rgba(222, 6, 92);

    /* Text color */
    --text-color-primary: white;
    --text-color-secondary: rgb(190, 190, 190);
    --text-color-tertiary: rgba(222, 40, 92);
    --text-color-forth: #fdfdfd;

    /* Button */
    --btn-primary: rgba(222, 40, 92);
    --btn-secondary: rgba(255, 255, 255, 0.7);
    --btn-tertiary: rgba(222, 30, 100, 0.7);

    /* Icon color: */
    /* default icon */
    --icon-default-primary: rgba(222, 40, 92);
    --icon-default-secondary: white;
    --icon-default-tertiary: #f5f5f5;

    /* Border color */
    --border-color-primary: rgba(222, 30, 100, 0.493);
    --border-color-secondary: #242424;

    /* Table */
    --table-bg: #292929;
    --table-color: rgba(255, 255, 255, 0.7);
    --table-border: rgb(255, 192, 203, 0.8);
    --table-header-bg: rgba(222, 30, 100, 0.95);
    --table-header-color: white;
    --table-odd-row-bg: rgb(72, 46, 56);
    --table-odd-row-color: rgba(255, 255, 255, 0.7);
    --table-active-row-bg: #14b8a6;
    --table-active-row-color: rgba(255, 255, 255, 0.7);

    /* Checkbox, switch */
    --toggle: rgba(255, 64, 129, 0.87);
    --toggle-light: rgba(255, 110, 158, 0.87);

    --inverted: 70%;
    --bg-transition: 0.25s ease;

    /* Shadow */
    --base-shadow: rgba(131, 70, 70, 0.12) 0px 2px 4px 0px, rgba(131, 70, 70, 0.32) 0px 2px 16px 0px;
    --hover-shadow: rgba(124, 66, 63, 0.122) 0px 0px 2px, rgba(124, 66, 63, 0.122) 0px 0px 4px,
        rgba(124, 66, 63, 0.122) 0px 0px 8px, rgba(124, 66, 63, 0.122) 0px 0px 12px,
        rgba(124, 66, 63, 0.122) 0px 0px 16px, rgba(124, 66, 63, 0.122) 0px 0px 35px;
    --button-shadow: rgba(230, 230, 230, 0.3) 0px 1px 2px 0px, rgba(230, 230, 230, 0.3) 0px 2px 6px 2px;
}
