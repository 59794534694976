mat-content {
    color: var(--text-color-tertiary) !important;
    background-color: var(--primary);
}

mat-sidenav mat-content {
    color: var(--text-color-tertiary) !important;
    background-color: var(--primary);
}

/* TODO(mdc-migration): The following rule targets internal classes of select that may no longer apply for the MDC version. */
mat-select .mat-select-value.mat-select-placeholder {
    color: var(--text-color-tertiary) !important;
}

mat-toast.subscription {
    width: fit-content;
    text-align: center;
}

/* TODO(mdc-migration): The following rule targets internal classes of input that may no longer apply for the MDC version. */
mat-input-container .mat-input {
    color: var(--text-color-primary);
}

/* TODO(mdc-migration): The following rule targets internal classes of input that may no longer apply for the MDC version. */
mat-input-container .mat-input[disabled] {
    color: var(--text-color-primary);
}

/* TODO(mdc-migration): The following rule targets internal classes of list that may no longer apply for the MDC version. */
mat-list-item:hover button {
    background-color: transparent !important;
}

/* TODO(mdc-migration): The following rule targets internal classes of input that may no longer apply for the MDC version. */
mat-input-container.mat-input-has-value label:not(.mat-no-float) {
    color: var(--text-color-tertiary);
    border-color: var(--text-color-tertiary) !important;
}

/* TODO(mdc-migration): The following rule targets internal classes of input that may no longer apply for the MDC version. */
mat-input-container label {
    color: var(--text-color-tertiary);
}

mat-select-menu mat-content mat-option:not([disabled]):hover {
    background: var(--tertiary);
}

mat-select-menu mat-content mat-option:not([disabled]):focus {
    background: var(--tertiary);
}

mat-select-menu mat-content mat-option:hover .mat-text.ng-binding {
    color: var(--btn-primary);
}

mat-select-menu.mat-default-theme mat-content mat-option[selected],
mat-select-menu mat-content mat-option[selected] {
    color: var(--text-color-tertiary);
}

/* TODO(mdc-migration): The following rule targets internal classes of dialog that may no longer apply for the MDC version. */
mat-dialog {
    min-width: 20em;
    border-top-left-radius: 30px !important;
    border-top-right-radius: 30px !important;
    border-bottom-left-radius: 15px !important;
    border-bottom-right-radius: 15px !important;
    color: var(--text-color-primary);
    background-color: var(--primary);
}

/* TODO(mdc-migration): The following rule targets internal classes of dialog that may no longer apply for the MDC version. */
mat-dialog.max-w-none {
    max-width: none;
}

/* TODO(mdc-migration): The following rule targets internal classes of input that may no longer apply for the MDC version. */
mat-input-container.mat-input-container--overflow-label > label {
    overflow: visible !important;
}

/* TODO(mdc-migration): The following rule targets internal classes of input that may no longer apply for the MDC version. */
mat-input-container label {
    overflow: visible !important;
}

.mat-mdc-fab > span > mat-icon {
    margin: 0;
    color: white !important;
}

mat-icon {
    min-height: 0;
    min-width: 0;
    margin: 0;
    color: var(--text-color-tertiary);
}

/* TODO(mdc-migration): The following rule targets internal classes of tabs that may no longer apply for the MDC version. */
mat-tabs {
    background: var(--primary);
}

mat-toast.mat-center {
    margin-left: auto;
    margin-right: auto;
    left: 0;
    right: 0;
    width: 35%;
    bottom: 50%;
    z-index: 9999999999;
}

mat-toast {
    position: fixed;
}

.check-all .mat-icon {
    border-color: var(--icon-default-secondary) !important;
}

/* TODO(mdc-migration): The following rule targets internal classes of list that may no longer apply for the MDC version. */
mat-list {
    padding: 0 !important;
}

/* TODO(mdc-migration): The following rule targets internal classes of tabs that may no longer apply for the MDC version. */
mat-tab-content > div {
    overflow: auto;
}

/* TODO(mdc-migration): The following rule targets internal classes of tabs that may no longer apply for the MDC version. */
mat-tabs .mat-mdc-tab {
    color: var(--text-color-tertiary);
    max-width: none !important;
}

/* TODO(mdc-migration): The following rule targets internal classes of tabs that may no longer apply for the MDC version. */
mat-tabs .mat-mdc-tab.mat-active {
    color: var(--icon-default-primary);
}

mat-backdrop.mat-sidenav-backdrop {
    position: fixed;
}

mat-bottom-sheet.ng-leave-active {
    transform: translate3d(0, 120%, 0) !important;
    transition: all 0.3s ease-in-out;
    opacity: 0.2;
}

mat-toolbar {
    color: white !important;
    background-color: var(--table-header-bg) !important;
    justify-content: center;
    align-items: center;
}

mat-toolbar > span {
    color: white !important;
}

mat-bottom-sheet .mat-mdc-subheader {
    background-color: var(--table-header-bg) !important;
    border-top-left-radius: 30px;
    border-top-right-radius: 30px;
}

mat-bottom-sheet {
    border-top-color: var(--primary) !important;
    background-color: var(--primary) !important;
}

mat-sidenav.mat-dark-theme,
mat-sidenav.mat-dark-theme mat-content {
    background: var(--primary);
    color: var(--text-color-primary);
}

mat-sidenav {
    background-color: var(--primary);
}

mat-divider {
    background-color: var(--text-color-tertiary);
}

mat-sidenav.dark-theme .wj-content,
div[wj-part='cells'] {
    background: var(--primary);
    color: var(--text-color-primary);
}

[dir] div[wj-part='cells'],
[dir] mat-sidenav.dark-theme .wj-content {
    color: var(--text-color-primary);
}

/* TODO(mdc-migration): The following rule targets internal classes of select that may no longer apply for the MDC version. */
mat-select-menu.mat-dark-theme mat-content {
    background: var(--primary);
}

/* TODO(mdc-migration): The following rule targets internal classes of select that may no longer apply for the MDC version. */
mat-select-menu._md mat-content {
    background: var(--primary);
    border: 3px solid;
    border-color: var(--tertiary);
}

/* TODO(mdc-migration): The following rule targets internal classes of select that may no longer apply for the MDC version. */
mat-select[disabled].mat-dark-theme .mat-text.ng-binding {
    color: rgba(255, 255, 255, 0.5);
}

/* TODO(mdc-migration): The following rule targets internal classes of menu that may no longer apply for the MDC version. */
mat-menu-content.mat-dark-theme {
    background: var(--primary);
}

mat-menu-content.mat-dark-theme mat-menu-item button {
    color: var(--text-color-secondary);
}

mat-select-menu.mat-dark-theme mat-content mat-option:not([disabled]):focus,
mat-select-menu.mat-dark-theme mat-content mat-option:not([disabled]):hover {
    background-color: rgba(158, 158, 158, 0.18);
}

mat-content.mat-dark-theme map button#secrets {
    background-color: var(--primary) !important;
}

mat-switch.mat-dark-theme.mat-checked .mat-bar {
    background-color: var(--slider-color) !important;
}

mat-switch.mat-dark-theme.mat-checked .mat-thumb-container .mat-thumb {
    background-color: var(--thumb-color) !important;
}

/* TODO(mdc-migration): The following rule targets internal classes of list that may no longer apply for the MDC version. */
mat-list-item:hover {
    background-color: var(--tertiary) !important;
}

/* TODO(mdc-migration): The following rule targets internal classes of list that may no longer apply for the MDC version. */
mat-list-item:hover span {
    color: var(--secondary);
}

.dashboard__options-bar mat-icon {
    color: white;
}

.mat-sidenav-left-help {
    position: fixed;
    font-weight: 500;
    z-index: 65;
}

.mat-sidenav-right:not(.mat-closed) .user-sb__header {
    opacity: 1;
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
    -webkit-transition: all ease-in-out 400ms;
    transition: all ease-in-out 400ms;
}

.mat-sidenav-right:not(.mat-closed) .user-sb__list-item {
    opacity: 1;
    -webkit-transform-origin: (100%, 0, 0);
    transform-origin: (100%, 0, 0);
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
    -webkit-transition: all ease-in-out 700ms;
    transition: all ease-in-out 700ms;
}

.user-sb__list-item mat-icon {
    margin: 0;
    margin-left: 10px;
    color: var(--text-color-tertiary);
}

.mat-sidenav-left-help:not(.mat-closed) .menu-wrap {
    opacity: 1;
    -webkit-transform-origin: (-100%, 0, 0);
    transform-origin: (-100%, 0, 0);
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
    -webkit-transition: all ease-in-out 700ms;
    transition: all ease-in-out 700ms;
}

.menu-wrap.help__group-items mat-list-item[ng-repeat].ng-enter {
    opacity: 0;
    -webkit-transform: translate3d(0, -10px, 0);
    transform: translate3d(0, -10px, 0);
}

.menu-wrap.help__group-items mat-list-item[ng-repeat].ng-enter-active {
    opacity: 1;
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
}

.menu-wrap.help__group-items mat-list-item[ng-repeat].ng-enter,
.menu-wrap.help__group-items.ng-hide-add,
.menu-wrap.help__group-items.ng-hide-remove {
    -webkit-transition: all ease-in-out 400ms;
    transition: all ease-in-out 400ms;
}

.mat-dark-theme .wj-content {
    background: var(--primary);
    color: var(--text-color-primary);
}

.mat-calendar {
    background: var(--primary);
    color: var(--text-color-primary);
}

.mat-calendar-date:hover {
    color: black;
}

.mat-mdc-button.mat-dark-theme:not([disabled]).mat-mdc-icon-button:hover {
    color: black;
}

.mat-calendar-month-label mat-icon {
    color: var(--text-color-primary);
}

th mat-icon {
    margin-left: 8px;
    color: white !important;
}

.material-tb mat-checkbox {
    margin: 0;
    margin-left: 15%;
}

.reports__jobs-table .mat-mdc-button {
    color: inherit;
}

.mat-bottom-sheet-custom {
    position: absolute;
    width: 20em;
    border-top-left-radius: 33px;
    border-top-right-radius: 33px;
    border-bottom-left-radius: 15px;
    border-bottom-right-radius: 15px;
    z-index: 70;
    padding: 0;
    bottom: auto;
    top: auto;
    left: auto;
    right: 40%;
    transition: all 0.3s cubic-bezier(0.25, 0.8, 0.25, 1);
}

.mat-sidenav-left:not(.mat-closed) .menu-wrap {
    opacity: 1;
    -webkit-transform-origin: (-100%, 0, 0);
    transform-origin: (-100%, 0, 0);
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
    -webkit-transition: all ease-in-out 700ms;
    transition: all ease-in-out 700ms;
}

.menu-wrap.report__list-group mat-list-item[ng-repeat].ng-enter,
.menu-wrap.report__list-group.ng-hide-add,
.menu-wrap.report__list-group.ng-hide-remove {
    -webkit-transition: all ease-in-out 400ms;
    transition: all ease-in-out 400ms;
}

.menu-wrap.report__list-group mat-list-item[ng-repeat].ng-enter {
    opacity: 0;
    -webkit-transform: translate3d(0, -10px, 0);
    transform: translate3d(0, -10px, 0);
}

.menu-wrap.report__list-group mat-list-item[ng-repeat].ng-enter-active {
    opacity: 1;
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
}

.mat-sidenav-right {
    overflow-x: hidden;
    overflow-y: auto;
}

.mat-drawer {
    overflow-x: hidden;
    overflow-y: scroll;
    max-width: 450px;
    box-shadow: 0 1px 8px 0 rgba(0, 0, 0, 0.2), 0 3px 4px 0 rgba(0, 0, 0, 0.14), 0 3px 3px -2px rgba(0, 0, 0, 0.12);
    background-color: var(--primary) !important;
    color: var(--text-color-secondary) !important;
}

.mat-subheader-content {
    color: white;
}

.mat-mdc-fab mat-icon {
    margin: auto;
}

.mat-datepicker-input {
    font-size: initial;
}

/* TODO(mdc-migration): The following rule targets internal classes of select that may no longer apply for the MDC version. */
.mat-select-value > span:not(.mat-select-icon) {
    padding: 5px;
    overflow: visible;
}

.mat-dark-theme .mat-calendar-date-disabled,
.mat-dark-theme .mat-calendar-month-label-disabled {
    color: rgba(255, 255, 255, 0.2);
}

.mat-dark-theme .mat-calendar-date-disabled:hover {
    color: rgba(255, 255, 255, 0.3);
}

.mat-text.ng-binding {
    color: var(--text-color-primary);
}

.mat-raised {
    border: 1px solid var(--secondary) !important;
    background-color: var(--secondary) !important;
    color: var(--text-color-forth) !important;
    box-shadow: var(--button-shadow);
    border-radius: 5px;
    transition: 0.2s ease-in-out;
}

.mat-raised mat-icon {
    color: white !important;
}

.mat-raised:hover {
    border: 1px solid var(--btn-tertiary) !important;
    background-color: var(--btn-tertiary) !important;
}

.mat-stroked {
    border: 1px solid var(--text-color-secondary) !important;
    color: var(--text-color-primary) !important;
    opacity: 0.9 !important;
    box-shadow: 0px 3px 1px -2px rgb(0 0 0 / 20%), 0px 2px 2px 0px rgb(0 0 0 / 14%), 0px 1px 5px 0px rgb(0 0 0 / 12%);
    border-radius: 5px;
    transition: 0.2s ease-in-out;
}

.mat-stroked mat-icon {
    color: var(--text-color-primary) !important;
}

.mat-stroked:hover {
    border: 1px solid var(--secondary) !important;
    color: var(--secondary) !important;
    opacity: 1 !important;
}

.mat-rounded {
    border-radius: 15px !important;
}

.mat-mdc-button[disabled] {
    border: 1px solid gray !important;
    background-color: rgba(0, 0, 0, 0.4) !important;
    box-shadow: 0px 0px 0px 0px rgba(72, 133, 237, 0.5) !important;
    opacity: 0.4;
}

.mat-mdc-button[disabled]:hover {
    color: rgba(0, 0, 0, 0.38);
}

.mat-mdc-fab:hover {
    opacity: 0.8;
}

.mat-mdc-button.fab--green.mat-mdc-fab {
    background-color: #00e676 !important;
}

.mat-mdc-button.fab--red.mat-mdc-fab {
    background-color: #db3236 !important;
}

.mat-mdc-button.fab--yellow.mat-mdc-fab {
    background-color: #ffc400 !important;
}

.mat-mdc-button.fab--white.mat-mdc-fab {
    background-color: #fff !important;
    transition: 300ms;
}

.mat-mdc-button.fab--blue.mat-mdc-fab {
    background-color: #0d47a1 !important;
}

.mat-mdc-button.mat-mdc-fab.fab--white > mat-icon {
    color: grey;
}

.mat-mdc-button.btn-mat-icon--80 {
    width: 60px;
    height: 60px;
    border-radius: 50px;
    background-color: var(--btn-primary) !important;
}

.mat-mdc-button.btn-mat-icon--80:hover {
    background-color: var(--btn-tertiary) !important;
}

.mat-mdc-button.left {
    border-radius: 10px 0 0 10px;
}

.mat-mdc-button.middle {
    border-radius: 0;
    border-left: 1px solid rgba(230, 230, 230, 0.96);
    border-right: 1px solid rgba(230, 230, 230, 0.96);
}

.mat-mdc-button.right {
    border-radius: 0 10px 10px 0;
}

.mat-mdc-button.wrap {
    white-space: pre-wrap;
    line-height: normal;
}

.mat-mdc-button:not([disabled]):hover {
    color: var(--icon-default-primary);
    background-color: transparent;
}

.mat-mdc-raised-button:not(:disabled) {
    background-color: var(--btn-primary) !important;
    color: var(--text-color-primary) !important;
}

.mat-mdc-raised-button:not(:disabled):hover {
    background-color: var(--btn-tertiary) !important;
}

.mat-default-theme .wizard-done.mat-raised {
    color: white;
    background-color: #3cba54;
}

.mat-default-theme .wizard-done.mat-raised:not([disabled]):hover {
    color: white;
    background-color: #11a954;
}

.mat-default-theme .wizard-error.mat-raised {
    color: white;
    background-color: #cc0000;
}

.mat-default-theme .wizard-error.mat-raised:not([disabled]):hover {
    color: white;
    background-color: #af0000;
}

.mat-sidenav-left-button .report-template-toggle-btn {
    right: 1.25em;
    -webkit-transition: 0.3s;
    transition: 0.3s;
}

.inv-search-field {
    margin: 0;
}

.inv-sidenav-button .locations-toggle-btn {
    right: 1em;
}

.mat-sidenav-left-button.menu-open .report-template-toggle-btn,
.inv-sidenav-button.menu-open .locations-toggle-btn {
    right: -14.5em;
    -webkit-transition: 0.3s;
    transition: 0.3s;
}

.mat-sidenav-left-button,
.inv-sidenav-button {
    display: inline-flex;
    position: fixed;
    margin-top: 80px;
    top: 0;
    z-index: 90;
}

.btn-mat-icon > mat-icon {
    height: 90%;
    width: 90%;
}

.fab--green mat-icon,
.fab--blue mat-icon,
.fab--red mat-icon {
    color: white !important;
}

.dashboard__fave-icons > mat-icon {
    height: 20px;
    width: 20px;
}

.widget__button mat-icon {
    color: var(--icon-default-secondary);
}

.home-buttons > mat-icon {
    height: 70%;
    width: 100%;
    color: var(--icon-default-primary);
}

.home-buttons:hover mat-icon {
    height: 70%;
    width: 100%;
    color: var(--icon-default-primary);
}

.dark-theme .wizard-done.mat-raised {
    color: white;
    background-color: #3cba54;
}

.dark-theme .wizard-error.mat-raised {
    color: white;
    background-color: #cc0000;
}

.dark-theme .wizard-done.mat-raised:not([disabled]):hover {
    color: white;
    background-color: #11a954;
}

.dark-theme .wizard-error.mat-raised:not([disabled]):hover {
    color: white;
    background-color: #af0000;
}

/* TODO(mdc-migration): The following rule targets internal classes of input that may no longer apply for the MDC version. */
[mat-component-id='thePanel'] mat-input-container {
    display: flex;
}

.options-bottom-sheet .mat-bottom-sheet-container {
    position: absolute !important;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    height: fit-content !important;
    width: 350px !important;
    min-width: 350px !important;
    max-width: 90vw !important;
    margin: auto;
    padding: 0px !important;
    border-top-left-radius: 33px !important;
    border-top-right-radius: 33px !important;
    border-bottom-left-radius: 15px;
    border-bottom-right-radius: 15px;
    box-shadow: 0 10px 10px -5px;
    overflow: unset !important;
}

.options-bottom-sheet .mat-mdc-nav-list {
    overflow: auto !important;
    max-height: 70vh !important;
    border-bottom-left-radius: 15px;
    border-bottom-right-radius: 15px;
}
.bottom-sheet-header {
    background-color: var(--table-header-bg) !important;
    border-top-left-radius: 30px;
    border-top-right-radius: 30px;
    color: white;
    font-size: 1.25em !important;
}

.mat-mdc-subheader {
    padding: 25px !important;
}

.mat-mdc-button {
    text-transform: uppercase;
}

/* TODO(mdc-migration): The following rule targets internal classes of list that may no longer apply for the MDC version. */
.mat-list-item-content {
    justify-content: space-between;
    border-bottom: 2px solid;
    border-bottom-color: currentcolor;
    border-color: var(--tertiary);
    font-size: 0.9em;
}

.mat-backdrop {
    opacity: 0.48;
    transition: opacity 0.45s;
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    background-color: rgba(33, 33, 33, 1);
    z-index: 80;
}

.mat-green {
    background-color: #00e676;
    color: white;
}

.mat-yellow {
    background-color: #ffc400;
    color: white;
}

.mat-blue {
    background-color: #0d47a1;
    color: white;
}

.mat-mdc-list .mat-mdc-list-item:hover {
    background-color: #d6eaf8;
}

mat-list mat-list-item > div :hover {
    color: var(--btn-tertiary);
}

.mat-sort-header-arrow {
    color: white !important;
}

.mat-drawer-backdrop {
    position: fixed !important;
}

/* .mat-tab-body.mat-tab-body-active{
    height: 50em!important;
} */

/* TODO(mdc-migration): The following rule targets internal classes of button that may no longer apply for the MDC version. */
.mat-button-focus-overlay {
    background-color: transparent !important;
}

/* TODO(mdc-migration): The following rule targets internal classes of dialog that may no longer apply for the MDC version. */
mat-mdc-dialog-actions {
    margin-bottom: 0 !important;
    padding: 8px 30px !important;
}

.mat-mdc-dialog-content {
    margin: 0 !important;
    padding: 8px 24px !important;
    background-color: var(--primary);
}

.mat-mdc-list-base .mat-mdc-list-item {
    color: var(--secondary) !important;
    cursor: pointer;
    font-size: 15px !important;
    background-color: var(--primary);
}

/* TODO(mdc-migration): The following rule targets internal classes of tabs that may no longer apply for the MDC version. */
.mat-tab-label {
    color: var(--text-color-primary) !important;
    text-transform: uppercase;
}

mat-label {
    color: var(--text-color-primary);
    opacity: 0.5;
    margin-right: 3px;
}

label > span {
    color: var(--text-color-tertiary);
}

/* TODO(mdc-migration): The following rule targets internal classes of tabs that may no longer apply for the MDC version. */
.mat-mdc-tab-body-wrapper {
    height: inherit;
}

/* TODO(mdc-migration): The following rule targets internal classes of tabs that may no longer apply for the MDC version. */
.mat-mdc-tab-body.mat-mdc-tab-body-active {
    height: inherit;
}

mat-form-field {
    margin: 10px 0;
}

/* TODO(mdc-migration): The following rule targets internal classes of button that may no longer apply for the MDC version. */
.mat-mdc-fab .mat-button-wrapper {
    padding: 0 !important;
}

.mat-mdc-dialog-actions {
    margin-bottom: 0 !important;
    margin-right: 1vw !important;
}

.mat-mdc-snack-bar-action .mdc-button__label {
    color: #ff4081 !important;
}

.mat-mdc-paginator-icon {
    fill: var(--text-color-primary);
}